import * as PropTypes from 'prop-types';
import { bemjoin, bemgen } from 'lib/bem';
import './ScreenContainer.scss';

function ScreenContainer({ children = null, className = undefined, ...rest }) {
    return (
        <section className={bemjoin(bemgen('ScreenContainer'), className)} {...rest}>
            {children}
        </section>
    );
}

ScreenContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

function ScreenSeparator({ ...rest }) {
    return <hr className="af_ScreenContainer__separator" {...rest} />;
}

export default ScreenContainer;
export { ScreenSeparator };
