import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { Control } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';

import { fetchTags } from 'store/tags/actions';
import { schedulesFetch } from 'store/schedules/actions';
import { SCHEDULES_SCOPE, SCHEDULES_ACTION } from 'store/schedules/constants';
import { fetchCategory } from 'store/categories/actions';
import { useCategoryWatch } from './useCategoryWatch';
import type { FormValues } from '../types';

export function useFormDataFetch({ asset, control }: { asset: Asset; control: Control<FormValues> }) {
    const dispatch = useDispatch();
    const { categoryId, prevCategoryId } = useCategoryWatch({ provider: asset.provider, control });

    useEffect(() => {
        const { tags } = asset.additional;
        if (tags?.length) {
            dispatch(fetchTags({ provider: asset.provider, ids: tags }));
        }
        dispatch(
            schedulesFetch({
                provider: asset.provider,
                assetId: asset.id,
                scope: SCHEDULES_SCOPE.ASSET,
                action: SCHEDULES_ACTION.PUBLISH,
            })
        );
        dispatch(
            schedulesFetch({
                provider: asset.provider,
                assetId: asset.id,
                scope: SCHEDULES_SCOPE.NOTIFICATION,
                action: SCHEDULES_ACTION.PUSH,
            })
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // fetching single category to get inherited metadata
    useEffect(() => {
        if (categoryId && categoryId !== prevCategoryId) {
            dispatch(fetchCategory(asset.provider, categoryId));
        }
    }, [categoryId, prevCategoryId, asset.provider, dispatch]);
}
