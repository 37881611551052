import * as PropTypes from 'prop-types';

import BEM from 'lib/bem';
import { Spinner } from '@schibsted-svp/react-ui';
import './LoadableContent.scss';

const bem = new BEM('LoadableContent');

function LoadableContent({ isLoading, children = null, className = '', size = 'small', ...props }) {
    if (isLoading) {
        return <Spinner size={size} {...props} containerClassName={[bem.block(), className].join(' ')} />;
    }

    return <div className={className}>{children}</div>;
}

LoadableContent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
};

LoadableContent.Size = Spinner.Size;

export default LoadableContent;
