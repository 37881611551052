import * as PropTypes from 'prop-types';
import config from 'config';
import { Button } from '@schibsted-svp/react-ui';
import css from './PosterUploader.module.scss';

const [minWidth, minHeight] = config.images.poster.upload.minimumSize;
const [maxWidth, maxHeight] = config.images.poster.upload.maximumSize;

function PosterUploader({ disabled = false, handlePosterChange, isEmpty }) {
    const handleClick = (event) => {
        // eslint-disable-next-line no-param-reassign
        event.target.value = null;
    };

    return (
        <div className={css.wrapper}>
            <h5>Upload image</h5>
            <div className={css.content}>{`Minimum image resolution ${minWidth}x${minHeight}`}</div>
            <div className={css.content}>{`Maximum image resolution ${maxWidth}x${maxHeight}`}</div>
            {!isEmpty ? (
                <Button className={css.button} type="label" size="small" disabled={disabled}>
                    Replace image
                    <input
                        id="uploadInput"
                        type="file"
                        onClick={handleClick}
                        onChange={handlePosterChange}
                        disabled={disabled}
                    />
                </Button>
            ) : null}
        </div>
    );
}

PosterUploader.propTypes = {
    disabled: PropTypes.bool,
    handlePosterChange: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool.isRequired,
};

export default PosterUploader;
