import cln from 'classnames';
import { Spinner } from '@schibsted-svp/react-ui';
import * as PropTypes from 'prop-types';
import css from './Placeholder.module.scss';

export default function Placeholder({ className, label, spinner = false }) {
    return (
        <div className={cln(css.placeholder, className)}>
            {spinner ? (
                <Spinner containerClassName={css.spinner} label={label} size="small" />
            ) : (
                <span className={css.label}>{label}</span>
            )}
        </div>
    );
}

Placeholder.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    spinner: PropTypes.bool,
};
