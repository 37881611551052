import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import FormPlayerContext from './PlayerContext';

function FormPlayerProvider({ children }) {
    const [player, setPlayer] = useState(null);
    const contextValue = useMemo(
        () => ({
            player,
            setPlayer,
        }),
        [player, setPlayer]
    );
    return <FormPlayerContext.Provider value={contextValue}>{children}</FormPlayerContext.Provider>;
}

FormPlayerProvider.propTypes = {
    children: PropTypes.node,
};

export default FormPlayerProvider;
