import { lazy, memo, Suspense, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { type SetValueConfig, type UseFormReturn, useWatch } from 'react-hook-form';
import type { Maybe, Optional } from 'types';
import type { ItemChangeHandler, TimelineItemRenderer, TimelineRef } from 'components/core/Timeline';
import { Timeline, TimelineItems, TimelineRuler } from 'components/core/Timeline';
import type { SubtitlesFormValues } from './types';
import { SubtitlesTimelineItem } from './SubtitlesTimelineItem';
import css from './SubtitlesTimeline.module.scss';

const AudioWaveform = lazy(() => import(/* webpackChunkName: 'audio-waveform' */ 'components/core/AudioWaveform'));

const VALUE_SET_OPTIONS: SetValueConfig = { shouldDirty: true, shouldTouch: true };
const TIMELINE_HEIGHT = 60;
const PIXELS_PER_SECOND = 70;
const TIMELINE_HEIGHT_WITH_PADDINGS = TIMELINE_HEIGHT + 20 * 2;

type SubtitlesTimelineProps = {
    className?: string;
    displayedIndex?: Optional<number>;
    duration: number;
    formApi: UseFormReturn<SubtitlesFormValues>;
    seekPlayer: (time: number) => void;
    url?: string;
};

export const SubtitlesTimeline = memo<SubtitlesTimelineProps>(function SubtitlesTimeline({
    className,
    duration,
    displayedIndex,
    formApi,
    seekPlayer,
    url,
}) {
    const ref = useRef<Maybe<TimelineRef>>(null);
    const { control, setValue } = formApi;
    const items = useWatch({
        control,
        name: `cues`,
    });

    const width = useMemo(() => duration * PIXELS_PER_SECOND, [duration]);

    const handleChange = useCallback<ItemChangeHandler>(
        ({ startTime, endTime }, index) => {
            setValue(`cues.${index}.startTime`, startTime, VALUE_SET_OPTIONS);
            setValue(`cues.${index}.endTime`, endTime, VALUE_SET_OPTIONS);
            seekPlayer(startTime);
        },
        [seekPlayer, setValue]
    );

    useLayoutEffect(() => {
        const { current: timeline } = ref;
        if (displayedIndex && timeline && !timeline.isDragging()) {
            timeline.scrollToIndex(displayedIndex);
        }
    }, [displayedIndex]);

    const renderItem = useCallback<TimelineItemRenderer>(
        (id, itemProps) => (
            <SubtitlesTimelineItem
                key={id}
                control={control}
                highlighted={displayedIndex === itemProps.index}
                {...itemProps}
            />
        ),
        [control, displayedIndex]
    );

    return (
        <Timeline duration={duration} className={className} pixelsPerSecond={PIXELS_PER_SECOND} ref={ref}>
            <TimelineRuler />
            <TimelineItems
                className={css.items}
                height={TIMELINE_HEIGHT}
                items={items}
                onChange={handleChange}
                renderItem={renderItem}
            />
            {url && (
                <Suspense fallback={null}>
                    <AudioWaveform
                        className={css.waveform}
                        url={url}
                        width={width}
                        height={TIMELINE_HEIGHT_WITH_PADDINGS}
                    />
                </Suspense>
            )}
        </Timeline>
    );
});
