import InfiniteScroll from 'react-infinite-scroller';
import { difference } from 'lodash';
import { FaSearch } from 'react-icons/fa';
import VideoCard from 'components/core/VideoCard';
import { AssetQualityTooltip } from 'components/asset-checklist/AssetQualityTooltip';
import { Spinner } from '@schibsted-svp/react-ui';
// import { ListPropTypes } from 'store/asset-list/validation';
import config, { getNewsroomConfig } from 'config';
// import { PreferencesPropTypes } from 'store/user/validation';
import { bemjoin } from 'lib/bem';
import { memo } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import { useFetchAssetsQuery } from 'services/svp-api-proxy-rtk/assets';
import css from './AssetsTable.module.scss';

const {
    assets: { columnOptions },
} = config;

export const AssetsTable = memo(function AssetsTable({ newsroom, fetchMore, list, type, preferences, items: assets }) {
    const loadMore = () => {
        if (list.isFetching || !list.nextUrl) {
            return;
        }

        fetchMore();
    };

    const { highlights } = list;
    const hiddenCols = difference(Object.keys(columnOptions), preferences.assetProperties);

    if (type !== 'linked') {
        hiddenCols.push('provider');
    }

    const assetsIds = assets.map(({ id }) => id);
    const previousIds = usePrevious(assetsIds);

    const { features } = getNewsroomConfig(newsroom);
    useFetchAssetsQuery(
        { provider: newsroom, assetId: difference(assetsIds, previousIds) },
        { skip: !features.playlistViews?.enabled }
    );

    return (
        <div>
            <VideoCard.Header hiddenColumns={hiddenCols} />
            {assets.length > 0 && (
                <InfiniteScroll
                    className={css.table}
                    loadMore={loadMore}
                    threshold={200}
                    hasMore={!!list.nextUrl}
                    initialLoad={false}
                    data-testid="assetsList"
                >
                    {assets.map((asset) => (
                        <VideoCard
                            key={`${asset.provider}-${asset.id}`}
                            className={bemjoin(css.row, highlights[asset.id] ? css['row-highlighted'] : '')}
                            asset={asset}
                            hiddenColumns={hiddenCols}
                            readOnly={type === 'linked'}
                        >
                            {newsroom === asset.provider && (
                                <AssetQualityTooltip assetId={asset.id} provider={asset.provider} />
                            )}
                        </VideoCard>
                    ))}
                    {list.isFetching && !!list.nextUrl && (
                        <Spinner containerClassName={css.spinner} label="Loading..." size="small" />
                    )}
                </InfiniteScroll>
            )}
            {list.isFetching && assets.length === 0 && <Spinner containerClassName={css.spinner} />}
            {!list.isFetching && assets.length === 0 && (
                <span className={css['no-results-info']}>
                    <FaSearch size={100} className={css['search-icon']} />
                    <br />
                    <br />
                    We cannot find the item you are searching for.
                    <br />
                    Maybe a little spelling mistake?
                </span>
            )}
        </div>
    );
});
