import { useEffect } from 'react';
import * as PropTypes from 'prop-types';

import { upperFirst } from 'lodash';
import { Button, Divider } from '@schibsted-svp/react-ui';
import VideoCard from 'components/core/VideoCard';
import classnames from 'classnames/bind';

import { getFirstUsernameFromEmail } from 'lib/email';
import { getStoredLongLiveVideoIds, setStoredLongLiveVideoIds, shouldShowLongLiveVideosNotification } from './utils';

import css from './PersonalVideosNotification.module.scss';

const cln = classnames.bind(css);

function NotificationSection({ assetList, caption, divider = false }) {
    return (
        <>
            {divider && <Divider margin="16px 0" />}
            <p className={css.caption}>{caption}</p>
            <ul>
                {assetList.map((asset) => (
                    <VideoCard
                        visibleIcons={['duration', 'date']}
                        variant="simple"
                        readOnly
                        asLink
                        size="compact"
                        asset={asset}
                        key={`personal-notification:${asset.provider}:${asset.id}`}
                    />
                ))}
            </ul>
        </>
    );
}

NotificationSection.propTypes = {
    assetList: PropTypes.arrayOf(PropTypes.object).isRequired,
    caption: PropTypes.string.isRequired,
    divider: PropTypes.bool,
};

function PersonalVideosNotification({
    newsroom,
    email,
    longLiveAssets,
    idleLiveAssets,
    failedLiveAssets,
    setMainNotificationVisibility,
}) {
    const userName = getFirstUsernameFromEmail(email);
    const liveAssetsIds = [...longLiveAssets, ...idleLiveAssets].map((longLiveAsset) => longLiveAsset.id);

    const storedLiveAssets = getStoredLongLiveVideoIds(email, newsroom);

    const keepLivesRunning = () => {
        setStoredLongLiveVideoIds(email, newsroom, liveAssetsIds);
    };

    const isLongVideosNotificationVisible = shouldShowLongLiveVideosNotification(liveAssetsIds, storedLiveAssets);

    useEffect(() => {
        if (isLongVideosNotificationVisible) {
            setMainNotificationVisibility(true);
        }
    }, [isLongVideosNotificationVisible, setMainNotificationVisibility]);

    return (
        <div className={css.container}>
            <div className={css['static-item']}>
                <h2>Hi {upperFirst(userName)}!</h2>
                <div
                    className={cln('dropdown-content', {
                        'dropdown-content--problematic': failedLiveAssets.length,
                    })}
                >
                    {failedLiveAssets.length > 0 ? (
                        <NotificationSection
                            assetList={failedLiveAssets}
                            caption="There seem to be problems with your live videos."
                        />
                    ) : null}
                    {idleLiveAssets.length > 0 ? (
                        <NotificationSection
                            assetList={idleLiveAssets}
                            caption="You have some Live videos that are in an idle state for a long time, what do you want to do?"
                            divider={failedLiveAssets.length > 0}
                        />
                    ) : null}
                    {longLiveAssets.length > 0 ? (
                        <NotificationSection
                            assetList={longLiveAssets}
                            caption="You have some Live videos that have been running for a long time, what do you want to do?"
                            divider={failedLiveAssets.length > 0 || idleLiveAssets.length > 0}
                        />
                    ) : null}
                </div>
            </div>
            {isLongVideosNotificationVisible && (
                <div className={css['keep-running-button']}>
                    <Button type="button" variant="standard" size="small" onClick={keepLivesRunning}>
                        Keep them running
                    </Button>
                </div>
            )}
        </div>
    );
}

PersonalVideosNotification.propTypes = {
    newsroom: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    longLiveAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    idleLiveAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    failedLiveAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMainNotificationVisibility: PropTypes.func.isRequired,
};

export default PersonalVideosNotification;
