import classnames from 'classnames/bind';
import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import css from './ConfirmationButton.module.scss';

const cln = classnames.bind(css);

function ConfirmationButton({
    children,
    connected = false,
    onClick,
    onConfirm,
    onCancel,
    size = 'big',
    dropdownPrimaryOption = false,
    className,
    variant = 'primary',
    disabled = false,
    loading = false,
    ...rest
}) {
    const [showConfirmation, toggleShowConfirmation] = useToggle();

    const handleClick = (event) => {
        toggleShowConfirmation();
        onClick?.(event);
    };

    const handleConfirm = (event) => {
        toggleShowConfirmation();
        onConfirm?.(event);
    };

    const handleCancel = (event) => {
        toggleShowConfirmation();
        onCancel?.(event);
    };

    const classNames = cln(
        css.container,
        `container--size-${size}`,
        {
            'container--dropdown': dropdownPrimaryOption,
            'container--confirm': showConfirmation,
            'container--connected': connected,
        },
        className
    );

    return (
        <div className={classNames}>
            <Button
                size={size}
                variant={variant}
                {...rest}
                type="button"
                className={cln(css.regularButton, {
                    'regularButton--hidden': showConfirmation,
                    'regularButton--expanded': dropdownPrimaryOption,
                })}
                onClick={handleClick}
                style={{ visibility: showConfirmation ? 'hidden' : 'visible' }}
                disabled={disabled}
                loading={loading}
            >
                {children}
            </Button>
            <Button
                type="button"
                variant="danger"
                size={size}
                className={cln(css.confirmButton, {
                    'confirmButton--hidden': !showConfirmation,
                })}
                onClick={handleConfirm}
                style={{ visibility: !showConfirmation ? 'hidden' : 'visible' }}
            >
                Confirm
            </Button>
            <Button
                type="button"
                variant="standard"
                size={size}
                className={cln(css.cancelButton, {
                    'cancelButton--hidden': !showConfirmation,
                })}
                onClick={handleCancel}
                style={{ visibility: !showConfirmation ? 'hidden' : 'visible' }}
            >
                Cancel
            </Button>
        </div>
    );
}

ConfirmationButton.propTypes = {
    variant: PropTypes.oneOf(['primary', 'standard', 'danger']),
    size: PropTypes.oneOf(['big', 'small', 'compact']),
    className: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.node.isRequired,
    connected: PropTypes.bool,
    // rendered as a dropdown primary button
    dropdownPrimaryOption: PropTypes.bool,
};

export default memo(ConfirmationButton);
