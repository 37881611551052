import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { StateFromReducersMapObject } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { all } from '@redux-saga/core/effects';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';

import { api as adminBffSdk } from 'services/admin-bff-sdk/generated';
import { adminBffRtkApi } from 'services/admin-bff-rtk-api';
import { accessDefinitionsApi } from 'services/svp-api-client/access-definitions';
import { svpApi } from 'services/svp-api-rtk/base-api';
import { svpApiProxy } from 'services/svp-api-proxy-rtk/base-api';
import { videoCompletionApi } from 'services/video-completion';
import { uploadAssetPreview, uploadCategoryPreview } from 'store/uploads/previews/slice';
import { rhfPersistentSlice } from 'components/AssetForm/hooks/useAssetFormPersistent';
import { deferredMiddleware } from './deferred';

import acl from './acl';
import analytics from './analytics';
import thumbnails from './thumbnails';
import video from './video';
import live from './live';
import assetList from './asset-list';
import assets from './assets';
import categories from './categories';
import clips from './clips';
import newsroom from './newsroom';
import providers from './providers';
import series from './series';
import tags from './tags';
import ui from './ui';
import auth from './auth';
import uploads from './uploads';
import user from './user';
import version from './version';
import playlists from './playlists';
import mqtt from './mqtt';
import schedules from './schedules';
import pushNotifications from './push-notifications';

const reducers = {
    acl: acl.reducers,
    live: live.reducers,
    newsroom: newsroom.reducers,
    assetList: assetList.reducers,
    assets: assets.reducers,
    categories: categories.reducers,
    clips: clips.reducers,
    tags: tags.reducers,
    providers: providers.reducers,
    series: series.reducers,
    ui: ui.reducers,
    auth: auth.reducers,
    user: user.reducers,
    thumbnails: thumbnails.reducers,
    video: video.reducers,
    uploads: uploads.reducers,
    version: version.reducers,
    playlists: playlists.reducers,
    schedules: schedules.reducers,
    pushNotifications: pushNotifications.slice.reducer,
    [adminBffSdk.reducerPath]: adminBffSdk.reducer,
    [svpApi.reducerPath]: svpApi.reducer,
    [svpApiProxy.reducerPath]: svpApiProxy.reducer,
    [accessDefinitionsApi.reducerPath]: accessDefinitionsApi.reducer,
    [adminBffRtkApi.reducerPath]: adminBffRtkApi.reducer,
    [videoCompletionApi.reducerPath]: videoCompletionApi.reducer,
    rhfPersistent: rhfPersistentSlice.reducer,
};

const rootReducer = combineReducers(reducers);

function* rootSaga() {
    yield all([
        ...acl.sagas,
        ...live.sagas,
        ...assetList.sagas,
        ...assets.sagas,
        ...categories.sagas,
        ...clips.sagas,
        ...newsroom.sagas,
        ...providers.sagas,
        ...series.sagas,
        ...tags.sagas,
        ...ui.sagas,
        ...auth.sagas,
        ...thumbnails.sagas,
        ...video.sagas,
        ...uploads.sagas,
        ...analytics.sagas,
        ...playlists.sagas,
        ...mqtt.sagas,
        ...schedules.sagas,
        ...pushNotifications.sagas,
        version.sagas,
    ]);
}

const sagaMiddleware = createSagaMiddleware();

const setupStore = (initialState = {}) => {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                // checking form makes the app working slow
                // uploads.subtitles and video.upload are ignored because they contain not serializable File
                serializableCheck: {
                    ignoredActions: [
                        'Asset/SAVE',
                        'Upload/VIDEO',
                        'Create/VIDEO',
                        'Subtitles/UPLOAD',
                        uploadAssetPreview.type,
                        uploadCategoryPreview.type,
                    ],
                    ignoredPaths: ['form'],
                },

                // checking form makes the app working slow
                // assets.items, clips and ui - immutability here has to be fixed
                immutableCheck: {
                    ignoredPaths: ['form', 'assets.items', 'clips', 'ui'],
                },
            })
                .concat(thunkMiddleware)
                .concat(deferredMiddleware)
                .concat(sagaMiddleware)
                .concat(svpApi.middleware)
                .concat(svpApiProxy.middleware)
                .concat(accessDefinitionsApi.middleware)
                .concat(adminBffRtkApi.middleware)
                .concat(adminBffSdk.middleware)
                .concat(videoCompletionApi.middleware),
    });

    sagaMiddleware.run(rootSaga);

    return store;
};

export type RootState = StateFromReducersMapObject<typeof reducers>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export default setupStore;
