import * as PropTypes from 'prop-types';
import Tooltip from 'components/ui/Tooltip';
import classnames from 'classnames/bind';
import css from './QuestionMark.module.scss';

const cln = classnames.bind(css);

function QuestionMark({ content, className, onClick }) {
    return (
        <Tooltip theme="light" content={content} offset={[15, 15]}>
            <span className={cln('questionMark', className)} onClick={onClick} />
        </Tooltip>
    );
}

QuestionMark.propTypes = {
    content: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default QuestionMark;
