import { useState, useRef } from 'react';
import * as PropTypes from 'prop-types';

import { Spinner } from '@schibsted-svp/react-ui';
import BEM from 'lib/bem';

import './AssetPreview.scss';
import './StreamSource.scss';

import { PurePlayer as Player, PLAYER_STATE } from 'components/core/Player';

const bem = {
    preview: new BEM('stream-preview'),
};

function StreamSource({ asset, hovered = false, position = 0, expandHeight = false }) {
    const api = useRef(null);
    const [playerState, setPlayerState] = useState(PLAYER_STATE.UNLOADED);

    const onReady = ({ svpPlayer, jwplayer }) => {
        svpPlayer.on('seeked', () => svpPlayer.pause());

        svpPlayer.on('error', () => setPlayerState(PLAYER_STATE.LOAD_ERROR));

        // take the lowest quality available to speed up preview
        svpPlayer.on('initialPlay', () => {
            jwplayer.on('levels', () => {
                const qualities = jwplayer.getQualityLevels();

                if (qualities && qualities.length > 0) {
                    // set the lowest quality
                    jwplayer.setCurrentQuality(qualities.length - 1);
                }

                setPlayerState(PLAYER_STATE.PLAYING);
            });
        });

        api.current = svpPlayer;
    };

    const onPlayerStateChange = ({ playerState: state }) => setPlayerState(state);

    return (
        <div className={bem.preview.block({ state: playerState, hovered })}>
            <div className={bem.preview.element('player')}>
                <Player
                    mute
                    asset={asset}
                    play={asset.streamType === 'live' && hovered}
                    autoplay={hovered}
                    position={asset.streamType === 'live' ? -1 : position}
                    onReady={onReady}
                    onChange={onPlayerStateChange}
                    expandHeight={expandHeight}
                />
            </div>

            {playerState === PLAYER_STATE.LOAD_ERROR && hovered && (
                <div className={bem.preview.element('error')}>no preview</div>
            )}

            {playerState === PLAYER_STATE.LOADING ||
                (playerState === PLAYER_STATE.BUFFERING && (
                    <div className={bem.preview.element('preview', { loading: true })}>
                        <Spinner size="compact" />
                    </div>
                ))}
        </div>
    );
}

StreamSource.propTypes = {
    asset: PropTypes.object.isRequired,
    hovered: PropTypes.bool,
    position: PropTypes.number,
    expandHeight: PropTypes.bool,
};

export default StreamSource;
