import * as PropTypes from 'prop-types';
import { getProgressLabel } from 'models/asset';
import { getProgressInfo } from 'store/video/navigation-tabs/selectors';
import { useSelector } from 'react-redux';

function VideoTabHeader({ provider, id, type, className }) {
    const {
        state: processingState,
        progress,
        type: progressType,
    } = useSelector((state) => getProgressInfo(state, { provider, id }));

    const progressValue = Number(progress).toFixed(0);
    const progressLabel = getProgressLabel({ type: progressType, state: processingState, progress: progressValue });
    const standardLabel = `${id} \u2014 ${type}`;

    return <div className={className}>{progressLabel || standardLabel}</div>;
}

VideoTabHeader.propTypes = {
    id: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default VideoTabHeader;
