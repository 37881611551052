import { isEmpty } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    MdFilterNone,
    MdAccessTime,
    MdRemoveRedEye,
    MdDateRange,
    MdVolumeOff,
    MdWatchLater,
    MdError,
} from 'react-icons/md';
import { CopyToClipboard, Initials } from '@schibsted-svp/react-ui';
import { useGetAccessDefinitionNameQueryState } from 'services/svp-api-client/access-definitions';
import AssetPreview from 'components/video/AssetPreview';
import TitleCell from 'components/AssetsList/AssetsTable/TitleCell';
import classnames from 'classnames/bind';
import Countdown from 'components/ui/Countdown';
import { getFormattedTimeWithDays } from 'lib/time';
import { LIVE_TYPES } from 'models/asset';
import { relative } from 'lib/date';
import ConditionalLink from 'components/core/ConditionalLink';

import useInterval from 'hooks/useInterval';
import { TagsCell } from './TagsCell';
import { getAdditionalStyles, getContainerStyles, areAdditionalColsHidden, thumbnailSizes } from './helpers';
import css from './VideoCard.module.scss';

const cln = classnames.bind(css);

const onCopyIdClick = (event) => {
    event.preventDefault();
};

function PlaybackTime({ duration, isLive = false }) {
    const [playbackTime, setPlaybackDuration] = useState(duration);

    useInterval(
        () => {
            setPlaybackDuration((prevTime) => prevTime + 1000);
        },
        isLive && duration !== undefined ? 1000 : null
    );

    const getTime = () => {
        if (isLive && playbackTime > 0) {
            return getFormattedTimeWithDays(playbackTime);
        }
        if (duration > 0) {
            return getFormattedTimeWithDays(duration);
        }

        return '00:00:00';
    };

    return (
        <div>
            <MdAccessTime className={css.icon} size={18} />
            {getTime()}
        </div>
    );
}

PlaybackTime.propTypes = {
    duration: PropTypes.number,
    isLive: PropTypes.bool,
};

function PaywallLevel({ asset, access: accessKey, className }) {
    const accessName = useGetAccessDefinitionNameQueryState({ provider: asset.provider, accessKey });
    return <span className={className}>Behind paywall{accessName ? `: ${accessName}` : ''}</span>;
}

PaywallLevel.propTypes = {
    asset: PropTypes.object.isRequired,
    access: PropTypes.string,
    className: PropTypes.string,
};

function VideoCard({
    children,
    className = '',
    hiddenColumns = [],
    variant,
    asLink = true,
    readOnly = false,
    noFollow = false,
    onClick = () => {},
    size = 'normal',
    controlsPosition = 'column',
    visibleIcons = ['ratio', 'duration', 'endTime', 'date', 'views', 'silent'],
    mappedAssetData,
    asset,
    progressElement = null,
    customType,
    additionalInfo = null,
    hasLiveVideoFailed = false,
}) {
    if (isEmpty(mappedAssetData)) {
        return null;
    }

    const {
        id,
        type,
        title,
        ratio,
        notes,
        duration,
        category,
        views,
        fullFormatDate,
        author,
        provider,
        isSilent,
        assetFormLink,
        startFlightTimes,
        inputInfos,
        endTime,
        isGeoblocked,
        isEncrypted,
        isFreeAccess,
        access,
    } = mappedAssetData;

    const isIconVisible = (iconName) => visibleIcons.includes(iconName);
    const isColumnVisible = (colName) => !hiddenColumns.includes(colName);
    const isDurationVisible = () => isIconVisible('duration') && duration !== undefined && !hasLiveVideoFailed;
    const isEndTimeVisible = () => isIconVisible('endTime') && endTime !== undefined;
    const isSilentVisible = () => isIconVisible('silent') && isSilent;
    const isCountdownVisible = () => ['LIVE', 'UPCOMING LIVE'].includes(type) && startFlightTimes && duration < 0;

    const isSimpleVariant = variant === 'simple';
    const titleComponent = readOnly ? (
        title
    ) : (
        <TitleCell key={`${id}:${provider.id}:${title}`} provider={provider.id} id={id} title={title} />
    );

    const defaultType = `${type}${type === 'UPCOMING LIVE' ? ` - ${relative(startFlightTimes)}` : ''}`;

    const isLiveType = LIVE_TYPES.includes(type);

    return (
        <ConditionalLink to={asLink && assetFormLink} className={css.link} onClick={onClick}>
            <div
                data-testid="videoCardContainer"
                className={cln('VideoCard', className, {
                    'VideoCard--simple': isSimpleVariant,
                    'VideoCard--hasFailed': hasLiveVideoFailed,
                })}
                style={getContainerStyles(hiddenColumns, variant, size)}
            >
                {progressElement}
                {isColumnVisible('preview') && (
                    <ConditionalLink to={!asLink && !noFollow && readOnly && assetFormLink}>
                        <div className={css.preview}>
                            <AssetPreview
                                asset={asset}
                                provider={provider.id}
                                width={thumbnailSizes[size]}
                                expandHeight
                            >
                                {isCountdownVisible() && <Countdown hideOnComplete date={startFlightTimes} />}
                            </AssetPreview>
                        </div>
                    </ConditionalLink>
                )}
                <div
                    className={cln('details', `details--${controlsPosition}`, `details--${size}`, {
                        'details--error': hasLiveVideoFailed,
                    })}
                >
                    {size !== 'compact' && (
                        <div className={cln('type', `type--${size}`)}>
                            {customType || defaultType} -{' '}
                            <CopyToClipboard onClick={onCopyIdClick} text={id} title="Click to copy ID" />
                            {inputInfos &&
                                inputInfos.map((inputInfo) => (
                                    <span key={`inputInfo:${inputInfo}`} className={cln({ 'input-info': inputInfo })}>
                                        {`${inputInfo} `}
                                    </span>
                                ))}
                            {additionalInfo}
                            {isGeoblocked && <span className={cln('input-info')}>Geoblocked</span>}
                            {isEncrypted && !isFreeAccess && (
                                <PaywallLevel asset={asset} access={access} className={cln('input-info')} />
                            )}
                            {isFreeAccess && <span className={cln('input-info')}>Free encryption</span>}
                        </div>
                    )}
                    <div type="button" className={cln('title', `title--${size}`)}>
                        <ConditionalLink to={!asLink && !noFollow && readOnly && assetFormLink}>
                            {titleComponent}
                        </ConditionalLink>
                    </div>
                    {!isSimpleVariant && <div className={css.notes}>{notes}</div>}
                    <div className={cln('meta', `meta--${size}`)}>
                        {isIconVisible('ratio') && (
                            <div>
                                <MdFilterNone className={css.icon} size={16} />
                                {ratio}
                            </div>
                        )}
                        {isDurationVisible() && <PlaybackTime duration={duration} isLive={isLiveType} />}
                        {isIconVisible('date') && (
                            <div>
                                <MdDateRange className={css.icon} size={18} />
                                {fullFormatDate}
                            </div>
                        )}
                        {views !== 0 && isIconVisible('views') && (
                            <div>
                                <MdRemoveRedEye className={css.icon} size={18} />
                                {views}
                            </div>
                        )}
                        {isEndTimeVisible() && (
                            <div>
                                <MdWatchLater className={css.icon} size={18} />
                                {endTime}
                            </div>
                        )}
                        {isSilentVisible() && (
                            <div>
                                <MdVolumeOff className={css.icon} size={18} />
                                Silent
                            </div>
                        )}
                    </div>
                    {hasLiveVideoFailed && <MdError className={cln('errorIcon', `errorIcon--${size}`)} />}
                    <div className={cln('controls', `controls--${controlsPosition}`)}>{children}</div>
                </div>
                {!isSimpleVariant && !areAdditionalColsHidden(hiddenColumns, variant) && (
                    <div className={css.additionals} style={getAdditionalStyles(hiddenColumns)}>
                        {isColumnVisible('tags') && (
                            <div className={css.tags}>
                                <TagsCell asset={asset} readOnly={readOnly} />
                            </div>
                        )}
                        {isColumnVisible('category') && <div className={css.category}>{category}</div>}
                        {isColumnVisible('date') && (
                            <div className={css.date}>
                                <MdDateRange className={css.icon} size={20} />
                                {fullFormatDate}
                            </div>
                        )}
                        {isColumnVisible('provider') && <div className={css.provider}>{provider.label}</div>}
                        {isColumnVisible('author') && (
                            <div className={css.author}>
                                <Initials title={author}>{author}</Initials>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ConditionalLink>
    );
}

VideoCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    hiddenColumns: PropTypes.arrayOf(
        PropTypes.oneOf(['provider', 'preview', 'tags', 'views', 'category', 'date', 'author'])
    ),
    /**
     * if true the whole card is clickable and redirects to an asset form after clicking
     *  otherwise the title and preview inherits this behavior
     */
    asLink: PropTypes.bool,
    /** if true the title of a video card is not editable */
    readOnly: PropTypes.bool,
    /** if true redirection to an asset form is available,
     * otherwise redirection is blocked
     */
    noFollow: PropTypes.bool,
    variant: PropTypes.oneOf(['simple']),
    /**
     * describes size variants of thumbnail
     * and font for title, stream type and meta data
     * */
    size: PropTypes.oneOf(['big', 'normal', 'compact']),
    /**
     * describes position of children
     * column - on the right side, centered towards other elements in a card
     * row - next to meta data
     */
    controlsPosition: PropTypes.oneOf(['row', 'column']),
    /** kinds of meta data to display */
    visibleIcons: PropTypes.arrayOf(PropTypes.oneOf(['ratio', 'duration', 'endTime', 'date', 'views', 'silent'])),
    mappedAssetData: PropTypes.object,
    asset: PropTypes.object,
    /** element that displays progress bar */
    progressElement: PropTypes.element,
    /** if provided it will be displayed instead of default asset's type */
    customType: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    additionalInfo: PropTypes.element,
    hasLiveVideoFailed: PropTypes.bool,
};

function Header({ hiddenColumns = [] }) {
    const isVisible = (colName) => !hiddenColumns.includes(colName);

    return (
        <div className={css.header} style={getContainerStyles(hiddenColumns)}>
            {isVisible('preview') && <div>PREVIEW</div>}
            <div>TITLE</div>
            {!areAdditionalColsHidden(hiddenColumns) && (
                <div className={css['header-details']} style={getAdditionalStyles(hiddenColumns)}>
                    {isVisible('tags') && <div className={css.tagsHeader}>TAGS</div>}
                    {isVisible('category') && <div>CATEGORY</div>}
                    {isVisible('date') && <div>PUBLISHED</div>}
                    {isVisible('provider') && <div>PROVIDER</div>}
                    {isVisible('author') && <div className={css.createdByHeader}>CREATED BY</div>}
                </div>
            )}
        </div>
    );
}

Header.propTypes = {
    hiddenColumns: PropTypes.arrayOf(
        PropTypes.oneOf(['provider', 'preview', 'tags', 'views', 'category', 'date', 'author'])
    ),
};

VideoCard.Header = Header;

export default VideoCard;
