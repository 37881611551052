import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as ReactGA from 'react-ga';

import { Button, Dialog, LabeledContainer } from '@schibsted-svp/react-ui';

export default function TrimConfirmationDialog({
    heading,
    message,
    onConfirmClick,
    loading,
    disabled,
    gaTrackedValue = '',
}) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (isDialogOpen && gaTrackedValue) {
            ReactGA.modalview(gaTrackedValue);
        }
    }, [gaTrackedValue, isDialogOpen]);

    const toggleDialog = () => {
        setIsDialogOpen((isOpen) => !isOpen);
    };

    const handleOnConfirmClick = () => {
        onConfirmClick();
        toggleDialog();
    };

    return (
        <LabeledContainer>
            <Dialog
                isOpen={isDialogOpen}
                onClose={toggleDialog}
                heading={heading}
                dialogTrigger={
                    <Button type="button" size="small" disabled={disabled} onClick={toggleDialog} loading={loading}>
                        {heading}
                    </Button>
                }
            >
                <Dialog.Section>{message}</Dialog.Section>
                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={toggleDialog}>
                        Cancel
                    </Button>
                    <Button type="button" onClick={handleOnConfirmClick}>
                        Confirm
                    </Button>
                </Dialog.Section>
            </Dialog>
        </LabeledContainer>
    );
}

TrimConfirmationDialog.propTypes = {
    heading: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    gaTrackedValue: PropTypes.string,
};
