import classnames from 'classnames/bind';
import * as PropTypes from 'prop-types';
import { InputError } from '@schibsted-svp/react-ui';
import css from './Radio.module.scss';

const cln = classnames.bind(css);

/**
 * This component accepts all default radio input props plus additional custom props.
 * It renders a radio input wrapped in a div tag. If the "label" prop is provided then the input is wrapped in a label tag.
 */
function Radio({
    checked,
    className,
    disabled = false,
    containerClassName,
    error,
    label,
    onChange = () => {},
    ...rest
}) {
    const Container = label ? 'label' : 'span';

    return (
        <Container
            className={cln(
                'Radio',
                {
                    'Radio--checked': checked,
                    'Radio--disabled': disabled,
                    'Radio--error': Boolean(error),
                    'Radio--labelled': Boolean(label),
                },
                containerClassName
            )}
        >
            <input
                type="radio"
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                className={cln('Radio__input', className)}
                {...rest}
            />
            {label}
            {error && <InputError>{error}</InputError>}
        </Container>
    );
}

Radio.propTypes = {
    /** @ignore */
    checked: PropTypes.bool,
    /** @ignore */
    className: PropTypes.string,
    /** Custom class name set on the input container */
    containerClassName: PropTypes.string,
    /** @ignore */
    disabled: PropTypes.bool,
    /** Error string which should be displayed below the input field */
    error: PropTypes.string,
    /** When provided, renders a label on the right of the checkbox */
    label: PropTypes.string,
    /** @ignore */
    onChange: PropTypes.func,
};

export default Radio;
