import { useState, useEffect, ReactElement } from 'react';
import * as ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { useWatch, Controller } from 'react-hook-form';
import { MdAccessTime } from 'react-icons/md';
import { Button, Checkbox, Select, DateTimeInput, Dialog, LabeledContainer } from '@schibsted-svp/react-ui';
import classnames from 'classnames/bind';

import config from 'config';
import { useGetAccessDefinitionOptionsQueryState } from 'services/svp-api-client/access-definitions';
import { isOnlyCurrentNewsroomSelected } from 'store/newsroom/selectors';
import { TagsSelect } from 'components/core/hookForm/TagsSelect';
import { StoriesSelect } from 'components/core/hookForm/StoriesSelect';
import { SourceSelect } from 'components/core/hookForm/SourceSelect';
import { CategorySelect } from 'components/core/hookForm/CategorySelect';
import { Mood } from 'components/core/hookForm/Mood';
import { TimeInput } from 'components/ui/Form/TimeInput';
import { objectToOptions } from 'components/ui/Form/Select';
import { usePrevious } from 'hooks/usePrevious';
import { NewsroomsSelect } from './NewsroomsSelect';
import { UserSearchInput } from './UserSearchInput';
import { useFiltersDialogForm, useFiltersDialogFormSubmit } from './hooks';
import type { FiltersDialogData } from './types';
import { DynamicFiltersContainer } from './DynamicFilters/DynamicFilters';
import css from './FiltersDialog.module.scss';

const cln = classnames.bind(css);

const { aspectRatios } = config;
const { streamType, assetType, status } = config.asset.labels;

export const formatDate = (value: number) => (value ? new Date(value * 1000) : null);
export const normalizeDate = (value: Date) => (value ? value.getTime() / 1000 : null);

interface FiltersDialogProps {
    initialValues: FiltersDialogData;
    provider: string;
    className?: string;
    hideStatusField?: boolean;
    hideAssetTypeField?: boolean;
    stopFormPropagation?: boolean;
    onFilter?: (data: FiltersDialogData) => void;
    dialogTrigger?: (callback: () => void) => ReactElement;
    newsroomsSelect?: boolean;
    showHiddenCheckbox?: boolean;
}

export function FiltersDialog({
    initialValues,
    provider,
    className,
    hideStatusField = false,
    hideAssetTypeField = false,
    stopFormPropagation = false,
    onFilter = () => {},
    dialogTrigger,
    newsroomsSelect = false,
    showHiddenCheckbox = false,
    ...rest
}: FiltersDialogProps) {
    const { formApi } = useFiltersDialogForm(initialValues);
    const { register, control, reset, setValue, unregister } = formApi;
    const [categories, excludedCategory, tags, excludedTags, after, before] = useWatch({
        name: ['categories', 'excludedCategory', 'tags', 'excludedTags', 'after', 'before'],
        control,
    });

    const [isOpened, setIsOpened] = useState(false);
    const isOnlyCurrentNewsroom = useSelector(isOnlyCurrentNewsroomSelected);
    const [isCategoryFilterDisabled, setIsCategoryFilterDisabled] = useState(!isOnlyCurrentNewsroom);

    const prevIsOpened = usePrevious(isOpened);

    useEffect(() => {
        setIsCategoryFilterDisabled(!isOnlyCurrentNewsroom);
    }, [isOnlyCurrentNewsroom]);

    useEffect(() => {
        if (isOpened) {
            ReactGA.modalview('advanced-filters');
        }
    }, [isOpened]);

    useEffect(() => {
        if (isOpened && prevIsOpened !== isOpened) {
            reset(initialValues);
        }
    }, [isOpened, prevIsOpened, initialValues, reset]);

    const toggle = () => setIsOpened(!isOpened);

    const handleSubmit = useFiltersDialogFormSubmit({
        formApi,
        stopFormPropagation,
        onFilter,
        onSuccess: toggle,
    });

    const accessDefinitionOptions = useGetAccessDefinitionOptionsQueryState({ provider });

    const labelProps = {
        labelWidth: 100,
        variant: 'horizontal' as const,
    };
    const categoryPlaceholder = isCategoryFilterDisabled ? `Available only for ${provider}` : undefined;

    return (
        <Dialog
            className={cln('filtersDialog', undefined, className)}
            isOpen={isOpened}
            heading="Advanced filters"
            dialogTrigger={dialogTrigger(toggle)}
            onClose={toggle}
            {...rest}
        >
            <form onSubmit={handleSubmit}>
                <Dialog.Section className={css.form}>
                    <LabeledContainer strict {...labelProps} label="Type:">
                        <Controller
                            name="types"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={objectToOptions(streamType, true)}
                                    splitButton={false}
                                    isMulti
                                />
                            )}
                        />
                    </LabeledContainer>
                    {!hideAssetTypeField ? (
                        <LabeledContainer strict {...labelProps} label="Asset type:">
                            <Controller
                                name="assetTypes"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={objectToOptions(assetType, true)}
                                        splitButton={false}
                                        isMulti
                                    />
                                )}
                            />
                        </LabeledContainer>
                    ) : null}
                    {!hideStatusField ? (
                        <LabeledContainer strict {...labelProps} label="Status:">
                            <Controller
                                name="statuses"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={objectToOptions(status, true)}
                                        splitButton={false}
                                        isMulti
                                    />
                                )}
                            />
                        </LabeledContainer>
                    ) : null}

                    {newsroomsSelect && (
                        <LabeledContainer strict {...labelProps} label="Newsroom:">
                            <NewsroomsSelect
                                control={control}
                                setValue={setValue}
                                setIsCategoryFilterDisabled={setIsCategoryFilterDisabled}
                            />
                        </LabeledContainer>
                    )}

                    <LabeledContainer {...labelProps} label="Category:" className={css.categoriesLabel}>
                        <div className={css.pairedFilters}>
                            <div>
                                <p className={css.label}>INCLUDED</p>
                                <CategorySelect
                                    name="categories"
                                    control={control}
                                    provider={provider}
                                    filterIds={excludedCategory?.map(({ value }) => value) || []}
                                    normalizeValue={false}
                                    isDisabled={isCategoryFilterDisabled}
                                    placeholder={categoryPlaceholder}
                                    isClearable
                                />
                            </div>
                            <div>
                                <p className={css.label}>EXCLUDED</p>
                                <CategorySelect
                                    name="excludedCategory"
                                    control={control}
                                    provider={provider}
                                    filterIds={categories ? [categories.value] : []}
                                    normalizeValue={false}
                                    isDisabled={isCategoryFilterDisabled}
                                    placeholder={categoryPlaceholder}
                                    isClearable
                                    isMulti
                                />
                            </div>
                        </div>
                    </LabeledContainer>

                    <LabeledContainer {...labelProps} label="Tags:" className={css.tagsLabel}>
                        <div className={css.pairedFilters}>
                            <div>
                                <p className={css.label}>INCLUDED</p>
                                <TagsSelect
                                    name="tags"
                                    control={control}
                                    provider={provider}
                                    placeholder="Search for tags"
                                    excludedTags={excludedTags}
                                />
                            </div>
                            <div>
                                <p className={css.label}>EXCLUDED</p>
                                <TagsSelect
                                    name="excludedTags"
                                    control={control}
                                    provider={provider}
                                    placeholder="Search for tags"
                                    excludedTags={tags}
                                />
                            </div>
                        </div>
                    </LabeledContainer>
                    <LabeledContainer strict {...labelProps} label="Stories:">
                        <StoriesSelect name="stories" control={control} provider={provider} />
                    </LabeledContainer>
                    <div className={css.rowFilters}>
                        <LabeledContainer strict horizontalLabelSpace="small" {...labelProps} label="Published:">
                            <div className={css.publishDates}>
                                <Controller
                                    name="after"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <DateTimeInput
                                            {...field}
                                            value={formatDate(field.value)}
                                            onChange={(value) => field.onChange(normalizeDate(value))}
                                            error={error?.message}
                                            placeholder="After"
                                            size="small"
                                            isClearable
                                            datePickerProps={{
                                                startDate: after ? new Date(after * 1000) : null,
                                                endDate: before ? new Date(before * 1000) : null,
                                                selectsStart: true,
                                                dateFormat: 'dd.MM.yyyy',
                                                wrapperClassName: css.publishDateAfter,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="before"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <DateTimeInput
                                            {...field}
                                            value={formatDate(field.value)}
                                            onChange={(value) => field.onChange(normalizeDate(value))}
                                            error={error?.message}
                                            placeholder="Before"
                                            size="small"
                                            isClearable
                                            datePickerProps={{
                                                startDate: after ? new Date(after * 1000) : null,
                                                endDate: before ? new Date(before * 1000) : null,
                                                selectsEnd: true,
                                                dateFormat: 'dd.MM.yyyy',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </LabeledContainer>
                        <LabeledContainer horizontalLabelSpace="small" variant="horizontal" width={200} label="Mood:">
                            <Mood name="moods" control={control} isMulti />
                        </LabeledContainer>
                    </div>
                    <LabeledContainer strict {...labelProps} label="Source:">
                        <SourceSelect
                            name="sources"
                            control={control}
                            size="big"
                            placeholder={undefined}
                            normalizeValue={false}
                            isMulti
                        />
                    </LabeledContainer>
                    <LabeledContainer strict {...labelProps} label="Aspect:" className={css.labelSmallMargin}>
                        <Controller
                            name="aspects"
                            control={control}
                            render={({ field }) => (
                                <Select {...field} options={aspectRatios} splitButton={false} isMulti />
                            )}
                        />
                    </LabeledContainer>
                    <div className={css.horizontalFilters}>
                        <LabeledContainer strict {...labelProps} label="Podcast:" className={css.labelZeroMargin}>
                            <Checkbox {...register('isPodcast')} containerClassName={css.checkbox} />
                        </LabeledContainer>
                        <LabeledContainer strict {...labelProps} label="Geoblock:" className={css.labelSmallMargin}>
                            <Checkbox {...register('isGeoblocked')} containerClassName={css.checkbox} />
                        </LabeledContainer>
                        {showHiddenCheckbox ? (
                            <LabeledContainer
                                strict
                                {...labelProps}
                                label="Show hidden assets:"
                                className={cln(css.labelSmallMargin, css.marginLeft)}
                            >
                                <Checkbox {...register('showHidden')} containerClassName={css.checkbox} />
                            </LabeledContainer>
                        ) : null}
                    </div>
                    <LabeledContainer strict {...labelProps} label="Viewer Access:">
                        <Controller
                            name="accessLevels"
                            control={control}
                            render={({ field }) => (
                                <Select {...field} options={accessDefinitionOptions} splitButton={false} isMulti />
                            )}
                        />
                    </LabeledContainer>
                    <LabeledContainer strict {...labelProps} label="Created by:">
                        <UserSearchInput control={control} />
                    </LabeledContainer>
                    <LabeledContainer strict horizontalLabelSpace="small" {...labelProps} label="Duration:">
                        <div className={css.durationInputs}>
                            <Controller
                                name="min"
                                control={control}
                                render={({ field }) => (
                                    <TimeInput
                                        value={field.value}
                                        onValueChange={field.onChange}
                                        size="small"
                                        placeholder="Min"
                                        icon={<MdAccessTime color="673ab8" />}
                                        iconPosition="right"
                                    />
                                )}
                            />
                            <Controller
                                name="max"
                                control={control}
                                render={({ field }) => (
                                    <TimeInput
                                        value={field.value}
                                        onValueChange={field.onChange}
                                        size="small"
                                        placeholder="Max"
                                        icon={<MdAccessTime color="673ab8" />}
                                        iconPosition="right"
                                    />
                                )}
                            />
                        </div>
                    </LabeledContainer>
                    <DynamicFiltersContainer
                        provider={provider}
                        categoryId={categories?.value}
                        register={register}
                        control={control}
                        unregister={unregister}
                    />
                </Dialog.Section>
                <Dialog.Section mode="flexRight" variant="darker">
                    <Button type="button" variant="standard" onClick={toggle}>
                        Cancel
                    </Button>
                    <Button type="submit">Apply</Button>
                </Dialog.Section>
            </form>
        </Dialog>
    );
}
