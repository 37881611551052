import * as PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import BEM from 'lib/bem';
import removeDocumentSelections from 'lib/dom';

import CloseIcon from './icons/CloseIcon';

const bem = new BEM('video-navigation');

function NavigationTab({ children, locationPath, error = false, onClose, assetId, progress = null }) {
    const location = useLocation();

    /**
     * This function does not use `match` parmeter by design
     * because the child routers e.g. vgtv/assets/140765/next-video are not
     * matched correctly
     * Once the issue can be solved, matching can be done by setting `exact` property
     * in NavLink
     * @returns {boolean}
     */
    const isActive = () => {
        const pathname = location.pathname.split('/').slice(0, 4).join('/');
        return pathname === locationPath.pathname;
    };

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();

        onClose({ active: isActive() });
    };

    const renderCloseButton = () => {
        if (!onClose) {
            return null;
        }

        return <CloseIcon onClose={handleClose} />;
    };

    return (
        <NavLink
            to={locationPath}
            onClick={removeDocumentSelections}
            isActive={isActive}
            className={bem.element('tab', { error })}
            activeClassName={bem.element('tab', { active: true })}
            data-testid={isActive() ? 'activeAssetTab' : undefined}
            data-asset-id={assetId}
        >
            {progress}
            <div className={bem.element('tab-content')}>{children}</div>
            {renderCloseButton()}
        </NavLink>
    );
}

NavigationTab.propTypes = {
    children: PropTypes.node.isRequired,
    locationPath: PropTypes.object.isRequired,
    error: PropTypes.bool,
    onClose: PropTypes.func,
    assetId: PropTypes.number,
    progress: PropTypes.element,
};

export { NavigationTab as RawNavigationTab };
export default NavigationTab;
