import { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { stopLiveStream } from 'store/live/actions';
import ConfirmationButton from 'components/core/ConfirmationButton/ConfirmationButton';

function LiveStopButton({
    children,
    assetId,
    provider,
    disabled = false,
    loading = false,
    size,
    dropdownPrimaryOption = false,
    className,
    connected = false,
}) {
    const dispatch = useDispatch();

    const handleStopClick = useCallback(() => {
        dispatch(stopLiveStream(provider, assetId));
    }, [assetId, provider, dispatch]);

    return (
        <ConfirmationButton
            connected={connected}
            variant="standard"
            loading={loading}
            size={size}
            disabled={disabled}
            onConfirm={handleStopClick}
            dropdownPrimaryOption={dropdownPrimaryOption}
            className={className}
        >
            {children}
        </ConfirmationButton>
    );
}

LiveStopButton.propTypes = {
    assetId: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    dropdownPrimaryOption: PropTypes.bool,
    className: PropTypes.string,
    loading: PropTypes.bool,
    size: PropTypes.oneOf(['big', 'small', 'compact']),
    children: PropTypes.string.isRequired,
    connected: PropTypes.bool,
};

export default LiveStopButton;
