import { takeEvery, select, call, put, all } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';

import { svpApiProxyClient } from 'services';
import * as Assets from 'store/assets/actionTypes';
import * as AssetList from 'store/asset-list/actionTypes';
import * as Tags from './actionTypes';
import * as Actions from './actions';
import { getTags } from './selectors';

/**
 *
 * @param {string} provider
 * @param {array} tags
 * @returns {IterableIterator<*>}
 */
function* filterUnfetched(provider, tags) {
    // filter out the `null` tags that are used somewhere in the app but haven't been fetched yet
    // eslint-disable-next-line no-unused-vars
    const missing = Object.entries(tags).filter(([, item]) => item.tag === null);

    // filter out those tags that are already being fetched
    // eslint-disable-next-line no-unused-vars
    const unfetched = missing.filter(([, item]) => item.isFetching === false);

    // if there are any tags left to be fetched
    if (unfetched.length === 0) {
        return false;
    }
    const ids = unfetched.map(([id]) => id);

    return yield put(Actions.fetchTags({ ids, provider }));
}

export function* fetchMissingTags() {
    try {
        const tags = yield select(getTags);

        yield all(
            Object.entries(tags).map(([provider, providerTags]) => call(filterUnfetched, provider, providerTags))
        );
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch missing tags',
            extras: {
                error,
            },
        });
    }
}

export function* fetchTags({ ids, provider }) {
    try {
        // convert the ids to an api filter property
        const filter = ids
            ? {
                  id: ids,
              }
            : undefined;

        const tags = yield call(svpApiProxyClient.fetchTags, { provider, filter });
        yield put(Actions.fetchTagsSuccess({ ids, provider, tags }));
    } catch (error) {
        yield put(Actions.fetchTagsError({ ids, provider, error: error.message }));
    }
}

export default [
    takeEvery(
        [AssetList.SET_ASSETS, AssetList.ADD_ASSETS, Assets.ASSET_FETCH_SUCCESS, Assets.ASSET_SAVE_SUCCESS],
        fetchMissingTags
    ),
    takeEvery(Tags.TAGS_FETCH, fetchTags),
];
