import { auth } from './auth';

const blacklistUrls = [/https?:\/\/localhost/i, /https?:\/\/localhost\.example\.com/i] as const;

const config = {
    api: {
        host: 'https://admin-api-development.int.k8s.stream.schibsted.media',
        path: '/api',
    },
    apiProxy: {
        host: 'https://svp-api-proxy-development.int.k8s.stream.schibsted.media',
        path: '/api',
    },
    adminBff: {
        host: 'https://admin-bff-development.int.k8s.stream.schibsted.media',
    },
    playlistsApi: {
        host: 'https://playlists-api-development.int.k8s.stream.schibsted.media',
    },
    mqtt: {
        url: 'wss://mqtt-development.int.k8s.stream.schibsted.media',
    },
    podcastsApi: {
        url: 'https://podcasts-api-development.int.k8s.stream.schibsted.media',
    },
    relatedApi: {
        url: 'https://related-api-development.int.k8s.stream.schibsted.media',
    },
    auth: {
        ...auth,
        url: 'https://auth-api-development.int.k8s.stream.schibsted.media',
    },
    ga: {
        trackingCode: 'UA-129902176-3',
    },
    player: {
        src: 'https://cdn.stream.schibsted.media/player/beta/player.next.min.js',
        options: {
            api: 'https://svp-stage.vg.no/svp/api/v1/',
            tokenUrl: 'https://token-development.k8s.stream.schibsted.media/svp/token/v1/',
            thumbnailsUrl: 'https://svp-stage.vg.no/svp/thumbnails/v1/',
            locale: 'en',
            settings: {
                preview: true,
                na: true,
                jw: { preload: 'none' },
            },
            sharing: false,
            recommended: false,
            autoplay: false,
            ttsSkin: false,
        },
    },
    sentry: {
        environment: 'dev',
        beforeSend: (event) => {
            if (blacklistUrls.some((pattern) => pattern.test(event.request.url))) {
                console.error(event); // eslint-disable-line no-console
                return null;
            }
            return event;
        },
    },
    live: {
        rtmp: {
            url: 'rtmp://172.232.150.52:30101/live',
        },
    },
} as const;

export default config;
