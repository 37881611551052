import { memo } from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from 'hooks/usePrevious';
import { FaCheck } from 'react-icons/fa';

import { CSSTransition } from 'react-transition-group';
import { Spinner } from '@schibsted-svp/react-ui';

import css from './MediaLiveInputInfoDialogState.module.scss';

function MediaLiveInputInfoDialogState({ type, state, containerClassName }) {
    const previousState = usePrevious(state);

    return (
        type === 'PUSH' && (
            <div className={containerClassName}>
                {['starting'].includes(state) && (
                    <div>
                        <Spinner size="compact" /> Entrypoint is being created in the cloud
                    </div>
                )}
                <CSSTransition
                    in={state === 'running' && previousState === 'starting'}
                    timeout={300}
                    unmountOnExit
                    classNames={css}
                >
                    <div>
                        <FaCheck className={css.checkmark} /> Entrypoint was created successfully
                    </div>
                </CSSTransition>
            </div>
        )
    );
}

MediaLiveInputInfoDialogState.propTypes = {
    type: PropTypes.oneOf(['PUSH', 'PULL']),
    state: PropTypes.string,
    containerClassName: PropTypes.string,
};

export default memo(MediaLiveInputInfoDialogState);
