import config from 'config';
import { MdAdd, MdVisibilityOff } from 'react-icons/md';
import { RiPushpinFill, RiUnpinFill } from 'react-icons/ri';
import { Button } from '@schibsted-svp/react-ui';
import VideoCard from 'components/core/VideoCard';
import Tooltip from 'components/ui/Tooltip';
import { MovablePlaylistItem } from './MovablePlaylistItem';
import type { PlaylistItemAsset } from './types';

import css from './RegularPlaylistItem.module.scss';

interface RegularPlaylistItemProps {
    provider: string;
    index: number;
    field: PlaylistItemAsset;
    openAddStaticVideoModal: (index: number) => void;
    isLastItem: boolean;
    pinItem: (index: number) => void;
    removeItem: (index: number) => void;
    moveItem: (fromIndex: number, toIndex: number) => void;
}

export function RegularPlaylistItem({
    provider,
    index,
    field,
    openAddStaticVideoModal,
    isLastItem,
    moveItem,
    pinItem,
    removeItem,
}: RegularPlaylistItemProps) {
    const { asset, isStatic } = field;

    const additionalInfo = (
        <>
            <span className={css.newsroom}>
                &nbsp;-&nbsp;&nbsp;
                {config.newsrooms[asset.provider]}
            </span>
            <span className={css.category}>{asset.category}</span>
        </>
    );
    return (
        <MovablePlaylistItem id={asset.id} index={index} moveItem={moveItem} disabled={!isStatic}>
            <VideoCard
                id={asset.id}
                provider={asset.provider}
                visibleIcons={['duration', 'date', 'views']}
                variant="simple"
                readOnly
                asLink={false}
                className={css.videoCard}
                noFollow={asset.provider !== provider}
                additionalInfo={additionalInfo}
            >
                <Button
                    size="small"
                    className={css.addButtonTop}
                    iconOnly
                    type="button"
                    onClick={() => {
                        openAddStaticVideoModal(index);
                    }}
                >
                    <MdAdd size={25} />
                </Button>
                {isLastItem && (
                    <Button
                        size="small"
                        className={css.addButtonBottom}
                        iconOnly
                        type="button"
                        onClick={() => {
                            openAddStaticVideoModal(index + 1);
                        }}
                    >
                        <MdAdd size={25} />
                    </Button>
                )}
                <div className={css.controls}>
                    <div className={css.controlButtons}>
                        {!isStatic && (
                            <Tooltip
                                theme="light"
                                content="Make this a static element locked to this position"
                                placement="top"
                                offset={[0, 12]}
                            >
                                <div>
                                    <Button
                                        type="button"
                                        variant="standard"
                                        size="small"
                                        iconOnly
                                        onClick={() => pinItem(index)}
                                    >
                                        <RiPushpinFill />
                                    </Button>
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip
                            theme="light"
                            content={
                                isStatic
                                    ? 'Remove this static element'
                                    : 'This asset should never appear in this dynamic playlist'
                            }
                            placement="top"
                            offset={[0, 12]}
                        >
                            <div>
                                <Button
                                    type="button"
                                    variant="standard"
                                    size="small"
                                    iconOnly
                                    onClick={() => removeItem(index)}
                                >
                                    {isStatic ? <RiUnpinFill /> : <MdVisibilityOff />}
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                    {isStatic && <RiPushpinFill size={16} className={css.pin} />}
                </div>
            </VideoCard>
        </MovablePlaylistItem>
    );
}
