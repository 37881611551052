import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function ConditionalLink({ to, children, ...rest }) {
    return to ? (
        <Link to={to} {...rest}>
            {children}
        </Link>
    ) : (
        <span {...rest}>{children}</span>
    );
}

ConditionalLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    children: PropTypes.node,
};

export default ConditionalLink;
