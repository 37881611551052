import PropTypes from 'prop-types';

import { Button } from '@schibsted-svp/react-ui';

import { ChangeStreamUrlsDialog } from 'components/video/ChangeStreamUrlsDialog';
import EmbedDialog from 'components/video/embed/EmbedDialog';
import DownloadControls from 'components/video/edit/Form/DownloadControls';

import css from './StreamControls.module.scss';

function StreamControls({
    id,
    provider,
    isReadyForDownloading = false,
    canBePreparedForDownloading,
    isPreparingForDownloading = false,
    fetchStreamConversion,
    disabled = false,
    control,
}) {
    const prepareDownloading = () => {
        fetchStreamConversion(provider, id);
    };

    return (
        <div className={css.container}>
            {isReadyForDownloading && <DownloadControls id={id} provider={provider} buttonOnly disabled={disabled} />}

            {canBePreparedForDownloading && (
                <Button
                    onClick={prepareDownloading}
                    variant="standard"
                    size="small"
                    type="button"
                    disabled={isPreparingForDownloading || disabled}
                >
                    Prepare file for downloading
                </Button>
            )}
            <ChangeStreamUrlsDialog provider={provider} assetId={id} disabled={disabled} control={control} />
            <EmbedDialog id={id} provider={provider} disabled={disabled} />
        </div>
    );
}

StreamControls.propTypes = {
    id: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    fetchStreamConversion: PropTypes.func.isRequired,
    canBePreparedForDownloading: PropTypes.bool.isRequired,
    isPreparingForDownloading: PropTypes.bool,
    isReadyForDownloading: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default StreamControls;
