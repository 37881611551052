import PropTypes from 'prop-types';

/**
 *
 * @param {String} className
 * @param {Number} width
 * @param {Number} height
 * @returns {*}
 * @constructor
 */
function SortIcon({ className, size }) {
    if (size === 'small') {
        return (
            <svg className={className} width="19px" height="13px" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g fill="#673AB8">
                    <path d="M10.334 1H1.666C1.298 1 1 1.447 1 2c0 .553.298 1 .666 1h8.668c.368 0 .666-.447.666-1 0-.553-.298-1-.666-1zM8.341 5H1.66C1.295 5 1 5.447 1 6c0 .553.295 1 .659 1H8.34C8.705 7 9 6.553 9 6c0-.553-.295-1-.659-1zM6.37 9H1.63C1.283 9 1 9.447 1 10c0 .553.282 1 .63 1h4.74c.348 0 .63-.447.63-1 0-.553-.282-1-.63-1zM17.697 7.5a1.074 1.074 0 0 0-1.465 0l-1.196 1.136V1.985C15.036 1.44 14.573 1 14 1s-1.036.44-1.036.985v6.653L11.768 7.5a1.074 1.074 0 0 0-1.465 0 .95.95 0 0 0 0 1.393l2.963 2.82c.194.184.457.288.732.288.275 0 .538-.104.732-.288l2.967-2.82a.95.95 0 0 0 0-1.393z" />
                </g>
            </svg>
        );
    }

    return (
        <svg className={className} width="23px" height="16px" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g fill="#673AB8">
                <path d="M14.002 1H.998A.999.999 0 0 0 0 2c0 .553.447 1 .998 1h13.004A.999.999 0 0 0 15 2c0-.553-.447-1-.998-1zM11.012 7H.988A.994.994 0 0 0 0 8c0 .553.442 1 .988 1h10.024A.994.994 0 0 0 12 8c0-.553-.442-1-.988-1zM8.054 13H.946C.423 13 0 13.447 0 14c0 .553.423 1 .946 1h7.108c.523 0 .946-.447.946-1 0-.553-.423-1-.946-1zM22.697 11.5a1.074 1.074 0 0 0-1.465 0l-1.196 1.136V1.985C20.036 1.44 19.573 1 19 1s-1.036.44-1.036.985v10.653L16.768 11.5a1.074 1.074 0 0 0-1.465 0 .95.95 0 0 0 0 1.393l2.963 2.82c.194.184.457.288.732.288.275 0 .538-.104.732-.288l2.967-2.82a.95.95 0 0 0 0-1.393z" />
            </g>
        </svg>
    );
}

SortIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
};

export default SortIcon;
