import { put, call, select, takeEvery } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { difference } from 'lodash';
import adminBffClient from 'services/admin-bff-client';
import {
    REGULAR_PLAYLISTS_FETCH_LIST,
    PLAYLIST_OF_PLAYLISTS_FETCH_LIST,
    playlistsFetchListSuccess,
    playlistsFetchListError,
} from 'store/playlists/list';
import { playlistsAssetsFetch, playlistOfPlaylistsItemsFetch } from 'store/playlists/items';
import { fetchPlaylistsAssetsController, fetchPlaylistsController } from 'store/playlists/items/sagas';
import { isPlaylistOfPlaylists, LABELS } from 'screens/NewsroomScreen/PlaylistsScreen/utils';

const PAGINATION_LIMIT = 10;
export function* fetchRegularPlaylistsList({ payload: { provider, name, labels, more } }) {
    try {
        const { page, hasNext, items } = yield select(({ playlists }) => playlists.list);
        if (!hasNext && more) return;
        const nextPage = more ? page + 1 : 1;

        let allPlaylists = [];

        if (labels.includes(LABELS.AUDIO)) {
            const audioLabels = [LABELS.AUDIO, LABELS.PODCAST];
            const labelsWithoutType = labels.filter((label) => label !== LABELS.AUDIO || label !== LABELS.PODCAST);

            for (const label of audioLabels) {
                const playlists = yield call(adminBffClient.fetchPlaylists, {
                    provider,
                    name,
                    labels: [label, ...labelsWithoutType],
                    limit: PAGINATION_LIMIT,
                    page: nextPage,
                });
                allPlaylists = [...allPlaylists, ...playlists];
            }
        } else {
            const playlists = yield call(adminBffClient.fetchPlaylists, {
                provider,
                name,
                labels,
                limit: PAGINATION_LIMIT,
                page: nextPage,
            });
            allPlaylists = playlists;
        }

        yield put(
            playlistsFetchListSuccess({
                provider,
                playlists: allPlaylists,
                page: nextPage,
                hasNext: allPlaylists.length === PAGINATION_LIMIT,
                more,
            })
        );

        const playlistsIds = allPlaylists.map(({ id }) => id);
        const missingPlaylists = difference(playlistsIds, items);
        const playlistsToFetch = allPlaylists.filter(
            (playlist) => !isPlaylistOfPlaylists(playlist) && missingPlaylists.includes(playlist.id)
        );

        yield put(playlistsAssetsFetch({ provider, playlists: playlistsToFetch }));
        yield call(fetchPlaylistsAssetsController, { playlists: playlistsToFetch, provider });
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch playlists list',
            extras: {
                error,
            },
        });
        yield put(playlistsFetchListError());
    }
}

export function* fetchPlaylistOfPlaylistsList({ payload: { provider, name, labels, more } }) {
    try {
        const { page, hasNext, items } = yield select(({ playlists }) => playlists.list);
        if (!hasNext && more) return;
        const nextPage = more ? page + 1 : 1;
        const playlists = yield call(adminBffClient.fetchPlaylists, {
            provider,
            name,
            labels,
            limit: PAGINATION_LIMIT,
            page: nextPage,
        });

        yield put(
            playlistsFetchListSuccess({
                provider,
                playlists,
                page: nextPage,
                hasNext: playlists.length === PAGINATION_LIMIT,
                more,
            })
        );
        const playlistsIds = playlists.map(({ id }) => id);
        const missingPlaylists = difference(playlistsIds, items);
        const nonEmptyPlaylists = playlists.filter((playlist) => playlist.items.length);

        const playlistsToFetch = nonEmptyPlaylists.filter(
            (playlist) => isPlaylistOfPlaylists(playlist) && missingPlaylists.includes(playlist.id)
        );

        yield put(playlistOfPlaylistsItemsFetch({ playlists: playlistsToFetch, provider }));
        yield call(fetchPlaylistsController, { playlists: playlistsToFetch, provider });
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch playlists list',
            extras: {
                error,
            },
        });
        yield put(playlistsFetchListError());
    }
}

export default [
    takeEvery(REGULAR_PLAYLISTS_FETCH_LIST, fetchRegularPlaylistsList),
    takeEvery(PLAYLIST_OF_PLAYLISTS_FETCH_LIST, fetchPlaylistOfPlaylistsList),
];
