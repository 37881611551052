import { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getFormattedTimeWithDays, MINUTE, SECOND } from 'lib/time';
import useInterval from 'hooks/useInterval';
import { afterLiveStreamEndTime, afterLiveStreamStartTime } from 'store/live/actions';

const calculateDuration = (time) => (time ? Date.now() - time * SECOND : undefined);

const calculateInterval = (prevTime) => prevTime && prevTime + SECOND;

function LiveDuration({ assetId, newsroom, startTime, endTime, isStarting, isRunning, isStopping, isIdle }) {
    const [duration, setDuration] = useState(calculateDuration(startTime));
    const [stopping, setStopping] = useState(false);
    const dispatch = useDispatch();
    const liveDuration = endTime ? endTime - startTime : undefined;

    const handleStarting = useCallback(() => {
        dispatch(afterLiveStreamStartTime(newsroom, assetId));
    }, [assetId, newsroom, dispatch]);

    const handleStopping = useCallback(() => {
        setStopping(true);
        dispatch(afterLiveStreamEndTime(newsroom, assetId));
    }, [assetId, newsroom, dispatch]);

    useEffect(() => {
        setDuration(() => calculateDuration(startTime));
    }, [startTime]);

    useEffect(() => {
        if (!isIdle && !isStarting && !isRunning && !isStopping && duration >= 0) {
            handleStarting();
        }
    }, [duration, isIdle, isStarting, isRunning, isStopping, handleStarting]);

    useEffect(() => {
        if (
            liveDuration &&
            !stopping &&
            !isStarting &&
            isRunning &&
            !isStopping &&
            duration >= liveDuration * SECOND &&
            duration < (liveDuration + MINUTE) * SECOND
        ) {
            handleStopping();
        }
    }, [duration, liveDuration, isStarting, isRunning, isStopping, handleStopping, stopping]);

    useInterval(() => {
        setDuration(calculateInterval);
    }, SECOND);

    return getFormattedTimeWithDays(Math.abs(duration));
}

LiveDuration.propTypes = {
    assetId: PropTypes.number.isRequired,
    newsroom: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number,
    isStarting: PropTypes.bool.isRequired,
    isRunning: PropTypes.bool.isRequired,
    isStopping: PropTypes.bool.isRequired,
    isIdle: PropTypes.bool.isRequired,
};

export default LiveDuration;
