import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FaPencilAlt, FaCheck } from 'react-icons/fa';
import classnames from 'classnames/bind';
import css from './InlineInput.module.scss';

const cln = classnames.bind(css);

const KEY_ENTER = 13;
const KEY_ESCAPE = 27;

function InlineInput({ onEdit, onReset, onSave, validate, value, isHighlighted = false }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const timeoutId = useRef(null);
    useEffect(() => {
        return () => {
            clearTimeout(timeoutId.current);
        };
    }, []);
    const handleEditClick = (event) => {
        event.preventDefault();
        setIsEditing(true);
    };

    const handleEditFinish = () => {
        setIsEditing(false);
        setIsSuccess(true);
        timeoutId.current = setTimeout(() => {
            setIsSuccess(false);
        }, 800);
        onSave();
    };

    const handlePencilClick = (event) => {
        if (isEditing) {
            event.stopPropagation();
            handleEditFinish();
        }
    };

    const resetField = () => {
        onReset();
        setIsEditing(false);
    };

    const handleEditKeyUp = ({ keyCode }) => {
        if (keyCode === KEY_ENTER) {
            handleEditFinish();
        }
        if (keyCode === KEY_ESCAPE) {
            resetField();
        }
    };

    const handleUpdate = ({ target }) => {
        onEdit(target.value);
    };

    return (
        <span
            tabIndex="0"
            role="button"
            className={cln('value', {
                'value--editing': isEditing,
                'value--highlighted': isHighlighted,
                'value--success': isSuccess,
                'value--error': !validate(value),
            })}
        >
            <span className={css.wrap} onClick={handleEditClick}>
                <span className={css.row}>{value}</span>
                {isEditing && (
                    <input
                        data-testid="inline-input"
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        value={value}
                        onBlur={handleEditFinish}
                        onKeyUp={handleEditKeyUp}
                        onChange={handleUpdate}
                    />
                )}
                {isSuccess ? (
                    <FaCheck className={css.checkmark} />
                ) : (
                    <FaPencilAlt className={css['pencil-icon']} onClick={handlePencilClick} />
                )}
            </span>
        </span>
    );
}

InlineInput.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool,
};

export default InlineInput;
