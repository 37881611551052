import PropTypes from 'prop-types';
import { Button, Dialog } from '@schibsted-svp/react-ui';

import css from './LiveCreationDialogFooter.module.scss';

export default function LiveCreationDialogFooter({ disabled, onSubmit, onCancel, submitButtonText = 'Create' }) {
    return (
        <Dialog.Section mode="flexRight" variant="darker" className={css.footer}>
            <Button type="button" variant="standard" onClick={onCancel}>
                Cancel
            </Button>

            <Button type="button" variant="primary" onClick={onSubmit} disabled={disabled}>
                {submitButtonText}
            </Button>
        </Dialog.Section>
    );
}

LiveCreationDialogFooter.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
};
