/* eslint-disable react/prefer-stateless-function */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@schibsted-svp/react-ui';
import PropTypes from 'prop-types';
import { isLiveStreamDuplicating, getDuplicatedAssetId } from 'store/live/selectors';
import { removeDuplicateLiveStreamInfo } from 'store/live/actions';

import ScreenContainer from 'components/ui/ScreenContainer';
import LiveAssetsList from 'components/LiveAssetsList/LiveAssetsList';
import css from './LiveScreen.module.scss';

function LiveScreen({
    isFetching = false,
    readyToPublishAssets = [],
    wasLiveAssets = [],
    currentLiveAssets = [],
    upcomingLiveAssets = [],
    personalLiveAssets = [],
    fetchLiveAssets,
    fetchLiveEncoders,
    fetchWasLiveAssets,
    newsroom,
    liveErrorMessage,
    wasLiveErrorMessage,
}) {
    useEffect(() => {
        fetchLiveAssets({ provider: newsroom });
    }, [fetchLiveAssets, newsroom]);

    useEffect(() => {
        fetchLiveEncoders(newsroom);
    }, [fetchLiveEncoders, newsroom]);

    useEffect(() => {
        fetchWasLiveAssets(newsroom);
    }, [fetchWasLiveAssets, newsroom]);

    const isDuplicating = useSelector(isLiveStreamDuplicating);
    const duplicatedAssetId = useSelector(getDuplicatedAssetId);
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        if (duplicatedAssetId && !isDuplicating) {
            history.push(`/${newsroom}/assets/${duplicatedAssetId}`);
            dispatch(removeDuplicateLiveStreamInfo());
        }
    }, [duplicatedAssetId, isDuplicating, dispatch, history, newsroom]);

    if (isDuplicating) {
        return (
            <ScreenContainer>
                <Spinner containerClassName={css.spinner} />
                <p className={css.text}>Duplication in progress...</p>
            </ScreenContainer>
        );
    }

    if (isFetching) {
        return (
            <ScreenContainer>
                <Spinner containerClassName={css.spinner} />
            </ScreenContainer>
        );
    }

    return (
        <ScreenContainer>
            {personalLiveAssets.length !== 0 && (
                <LiveAssetsList
                    header="My live videos"
                    visibleIcons={['date', 'views', 'duration', 'endTime']}
                    assets={personalLiveAssets}
                    errorMessage={liveErrorMessage}
                    fetchAssets={fetchLiveAssets}
                    newsroom={newsroom}
                    className={css['personal-lives']}
                />
            )}
            <div className={css['live-screen']}>
                <div className={css['live-and-was-live']}>
                    <div className={css['live-videos']}>
                        <LiveAssetsList
                            header="Current live videos"
                            visibleIcons={['date', 'views', 'duration', 'endTime']}
                            assets={currentLiveAssets}
                            errorMessage={liveErrorMessage}
                            fetchAssets={fetchLiveAssets}
                            newsroom={newsroom}
                            className={css['current-live']}
                        />
                        <LiveAssetsList
                            header="Upcoming live videos"
                            visibleIcons={['date', 'views', 'duration', 'endTime']}
                            assets={upcomingLiveAssets}
                            errorMessage={liveErrorMessage}
                            fetchAssets={fetchLiveAssets}
                            newsroom={newsroom}
                        />
                    </div>
                    <LiveAssetsList
                        header="Was live (last 24 hours)"
                        visibleIcons={['date', 'views', 'duration', 'endTime']}
                        assets={wasLiveAssets}
                        errorMessage={wasLiveErrorMessage}
                        fetchAssets={fetchWasLiveAssets}
                        newsroom={newsroom}
                    />
                </div>
                <LiveAssetsList
                    header="Ready to be published"
                    visibleIcons={[]}
                    assets={readyToPublishAssets}
                    errorMessage={liveErrorMessage}
                    fetchAssets={fetchLiveAssets}
                    newsroom={newsroom}
                />
            </div>
        </ScreenContainer>
    );
}

LiveScreen.propTypes = {
    currentLiveAssets: PropTypes.arrayOf(PropTypes.object),
    upcomingLiveAssets: PropTypes.arrayOf(PropTypes.object),
    readyToPublishAssets: PropTypes.arrayOf(PropTypes.object),
    wasLiveAssets: PropTypes.arrayOf(PropTypes.object),
    personalLiveAssets: PropTypes.arrayOf(PropTypes.object),
    fetchLiveAssets: PropTypes.func.isRequired,
    fetchLiveEncoders: PropTypes.func.isRequired,
    fetchWasLiveAssets: PropTypes.func.isRequired,
    newsroom: PropTypes.string.isRequired,
    isFetching: PropTypes.bool,
    liveErrorMessage: PropTypes.string,
    wasLiveErrorMessage: PropTypes.string,
};

export default LiveScreen;
