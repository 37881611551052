import { useState, useEffect } from 'react';
import config from 'config';
import * as PropTypes from 'prop-types';
import * as ReactGA from 'react-ga';
import { Dropdown, Notification, Initials } from '@schibsted-svp/react-ui';
import authToken from 'models/authToken';
import classnames from 'classnames/bind';
import AdminUpdateNotification from 'components/core/AdminUpdateNotification';
import PersonalVideosNotification from 'components/core/PersonalVideosNotification';

import { authApiClient } from 'services';
import css from './Avatar.module.scss';

const cln = classnames.bind(css);

const renderButton = (onClick, _, { email }) => {
    return (
        <Initials as="button" size="big" title={email} onClick={onClick}>
            {email}
        </Initials>
    );
};

const signOut = async () => {
    try {
        await authApiClient.logout();
        authToken.clear();
        ReactGA.outboundLink({ label: 'Sign out' }, () => window.location.replace('/'));
    } catch (err) {
        ReactGA.exception({
            description: 'Something went wrong during sign out',
        });
        Notification.notify.error(
            'Something went wrong during sign out. Please contact us on Slack or on mail: svp@schibsted.com'
        );
    }
};

function Avatar({
    newsroom,
    email,
    longLiveAssets,
    idleLiveAssets,
    failedLiveAssets,
    isMainNotificationVisible = false,
    setMainNotificationVisibility,
    nextVersion,
}) {
    const [isUpdateNotificationDismissedByUser, setIsUpdateNotificationDismissedByUser] = useState(false);

    // eslint-disable-next-line no-undef
    const isNewVersionAvailable = __RELEASE__ !== nextVersion;

    const shouldShowSeparateUpdateNotification =
        isNewVersionAvailable && !isMainNotificationVisible && !isUpdateNotificationDismissedByUser;

    const shouldShowPersonalNotification = [...longLiveAssets, ...idleLiveAssets, ...failedLiveAssets].length > 0;

    useEffect(() => {
        // eslint-disable-next-line no-undef
        if (isNewVersionAvailable) {
            setIsUpdateNotificationDismissedByUser(false);
        }
    }, [isNewVersionAvailable, nextVersion]);

    const closeNotification = (event) => {
        event.stopPropagation();
        setIsUpdateNotificationDismissedByUser(true);
    };

    return (
        <div className={css.container}>
            <Dropdown
                hideOnOutsideClick
                showMenuArrow
                size="small"
                className={css.avatar}
                type="button"
                menuGap={15}
                menuArrowClassName={css.dropdownArrow}
                mainButtonRenderer={renderButton}
                email={email}
                onClick={() => {
                    setMainNotificationVisibility(!isMainNotificationVisible);
                }}
                onOutsideClick={() => {
                    if (isMainNotificationVisible) {
                        setMainNotificationVisibility(false);
                    }
                }}
                onOptionsMenuClick={() => {
                    if (isMainNotificationVisible) {
                        setMainNotificationVisibility(false);
                    }
                }}
                expanded={isMainNotificationVisible}
            >
                {shouldShowPersonalNotification && (
                    <Dropdown.Item itemKey="myLives" className={cln('item', 'static-item', 'bottom-line')}>
                        <PersonalVideosNotification
                            newsroom={newsroom}
                            email={email}
                            longLiveAssets={longLiveAssets}
                            idleLiveAssets={idleLiveAssets}
                            failedLiveAssets={failedLiveAssets}
                        />
                    </Dropdown.Item>
                )}
                {isNewVersionAvailable && (
                    <Dropdown.Item itemKey="notificationContent" className={cln('bottom-line')}>
                        <AdminUpdateNotification />
                    </Dropdown.Item>
                )}

                <Dropdown.Item itemKey="changelog" className={cln('item', 'bottom-line')}>
                    <a
                        href={config.announcekitChangelog}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={css['full-width']}
                    >
                        Changelog
                    </a>
                </Dropdown.Item>

                <Dropdown.Item itemKey="signout" onClick={signOut} className={css.item}>
                    Sign out
                </Dropdown.Item>
            </Dropdown>
            {shouldShowSeparateUpdateNotification && (
                <AdminUpdateNotification displaySeparateWindow onClose={closeNotification} />
            )}

            <span
                className={cln('counter', {
                    visible: shouldShowPersonalNotification,
                    'counter--red': failedLiveAssets.length,
                })}
            >
                {failedLiveAssets.length || longLiveAssets.length + idleLiveAssets.length}
            </span>
        </div>
    );
}

Avatar.propTypes = {
    newsroom: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    longLiveAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    idleLiveAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    failedLiveAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMainNotificationVisible: PropTypes.bool,
    setMainNotificationVisibility: PropTypes.func.isRequired,
    nextVersion: PropTypes.string.isRequired,
};

export default Avatar;
