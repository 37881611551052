import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { cloneDeep, get } from 'lodash';

import BEM from 'lib/bem';
import config from 'config';

import { hasSubtitlesChanged } from 'models/asset';
import { secondsToTime } from 'lib/time';
import { createPlayer, PLAYER_STATE } from './playerApi';
import { useSignAssetStreamUrls } from './hooks';
import './Player.scss';

const bem = new BEM('player');

const PLAYER_PROP_TYPES = {
    asset: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        streamType: PropTypes.string.isRequired,
        assetType: PropTypes.string.isRequired,
        images: PropTypes.shape({
            main: PropTypes.string.isRequired,
            snapshots: PropTypes.string,
        }).isRequired,
        streamUrls: PropTypes.shape({
            hls: PropTypes.string.isRequired,
        }).isRequired,
        flightTimes: PropTypes.shape({
            start: PropTypes.number,
            end: PropTypes.number,
        }),
        additional: PropTypes.object.isRequired,
        streamConfiguration: PropTypes.shape({
            properties: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
    }).isRequired,
    'data-testid': PropTypes.string,
    SvpPlayer: PropTypes.func.isRequired,
    play: PropTypes.bool,
    mute: PropTypes.bool,
    autoplay: PropTypes.bool,
    position: PropTypes.number,
    options: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    pauseOnSeek: PropTypes.bool,
    expandHeight: PropTypes.bool,
    className: PropTypes.string,
    noPoster: PropTypes.bool,
};

class Player extends Component {
    player = {
        isLoaded: false,
    };

    static propTypes = PLAYER_PROP_TYPES;

    static defaultProps = {
        'data-testid': undefined,
        play: false,
        mute: false,
        autoplay: false,
        position: null,
        pauseOnSeek: false,
        options: {},
        onChange: () => {},
        onReady: () => {},
        expandHeight: false,
        noPoster: false,
        className: undefined,
    };

    state = {
        play: this.props.play,
    };

    constructor(props) {
        super(props);
        this.nodeId = `player-${new Date().getTime()}`;
    }

    componentDidMount() {
        this.initializePlayer(this.props);
    }

    componentDidUpdate({ asset: oldAsset, ...prevProps }) {
        const { player } = this;
        const { asset, position } = this.props;
        const { play } = this.state;

        if (!player || player.isLoaded === false) {
            return;
        }

        if (asset.streamType === 'live' && position === -1) {
            player.seekLive(play);
        } else if (prevProps.position !== position) {
            player.seek((asset.duration / 1000) * position, play);
        }

        if (
            this.hasAssetChanged(oldAsset) ||
            this.hasAssetSourceFilesChanged(oldAsset) ||
            this.hasAssetSubtitlesChanged(oldAsset) ||
            this.hasAssetPosterChanged(oldAsset)
        ) {
            player.load(cloneDeep(asset));
        }
    }

    componentWillUnmount() {
        const { player } = this;

        if (player && player.remove && player.isLoaded) {
            player.remove();
        }
    }

    initializePlayer = async ({
        asset,
        SvpPlayer,
        onReady,
        onChange,
        autoplay,
        mute,
        position,
        options,
        pauseOnSeek,
    }) => {
        const startTime = asset.duration && position ? Math.round((asset.duration / 1000) * position) : null;

        const player = createPlayer(
            new SvpPlayer({
                ...config.player.options,
                ...options,
                mute,
                autoplay,
                node: this.nodeId,
                asset: cloneDeep(asset),
                vendor: asset.provider,
                time: secondsToTime(startTime),
            })
        );
        player.onStateChange = (newState) => onChange(newState);

        const { svpPlayer } = player;

        svpPlayer.on('error', () => {
            this.player = null;
        });
        svpPlayer.on('ready', () => onReady(player));
        svpPlayer.on('time', () => {
            const { play } = this.state;
            if (play === false) {
                player.pause();
            }
        });

        if (pauseOnSeek) {
            svpPlayer.on('seek', () => player.pause());
        }

        svpPlayer.on('play', () => {
            this.setState({ play: true });
        });

        this.player = player;
    };

    hasAssetSourceFilesChanged(asset) {
        return get(this.props.asset, 'additional.sourceFiles.length') !== get(asset, 'additional.sourceFiles.length');
    }

    hasAssetSubtitlesChanged(asset) {
        return hasSubtitlesChanged(asset, this.props.asset.additional.subtitles);
    }

    hasAssetChanged(oldAsset) {
        const hasPropertyChanged = (propertyName) =>
            get(this.props.asset, propertyName) !== get(oldAsset, propertyName);

        const result = [
            'additional.metadata.source',
            'streamUrls.hls',
            'duration',
            'playback.begin',
            'playback.end',
        ].some(hasPropertyChanged);

        return this.props.asset.streamType !== 'live'
            ? result
            : result || ['flightTimes.start', 'flightTimes.end'].some(hasPropertyChanged);
    }

    hasAssetPosterChanged(oldAsset) {
        return !this.state.play && this.props.asset?.images.main !== oldAsset?.images.main;
    }

    render() {
        const {
            asset: { images, title },
            expandHeight,
            className,
            noPoster,
        } = this.props;
        const src = `${images.main}?t[]=x452q80`;

        return (
            <div className={[bem.block({ expandHeight }), className].join(' ')} data-testid={this.props['data-testid']}>
                <img
                    className={[bem.element('poster', { noPoster }), bem.element(this.props.asset.assetType)].join(' ')}
                    src={src}
                    alt={title}
                />
                <div className={bem.element('wrapper')}>
                    <div id={this.nodeId} />
                </div>
            </div>
        );
    }
}

export function SignStreamUrls({ asset, ...rest }) {
    const { asset: signedAsset, isLoading } = useSignAssetStreamUrls(asset);
    if (isLoading) {
        return null;
    }
    return <Player {...rest} asset={signedAsset} />;
}

SignStreamUrls.propTypes = PLAYER_PROP_TYPES;

export { SignStreamUrls as default, PLAYER_STATE };
