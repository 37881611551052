import { put, call, takeEvery, select, throttle } from '@redux-saga/core/effects';
import { reportMessageToSentry } from 'lib/error';
import { adminBffClient, svpApiProxyClient } from 'services';
import { fetchPlaylistsAssetsController } from 'store/playlists/items/sagas';
import {
    STATIC_VIDEOS_SEARCH,
    STATIC_VIDEOS_SEARCH_MORE,
    PLAYLISTS_SEARCH,
    staticItemsFetchSuccess,
    staticItemsFetchError,
    staticPlaylistsFetchSuccess,
} from 'store/playlists/staticItems';
import { playlistsAssetsFetch } from 'store/playlists/items';

import { getStaticItemsData } from 'store/playlists/selectors';

import { isPlaylistOfPlaylists } from 'screens/NewsroomScreen/PlaylistsScreen/utils';
import { keyBy } from 'lodash';

const PAGINATION_LIMIT = 5;

export function* fetchStaticVideos({ payload: { searchTerm, searchQueryProviders, parameters } }) {
    try {
        const { assets, nextUrl } = yield call(svpApiProxyClient.fetchFilteredAssets, {
            provider: searchQueryProviders,
            query: searchTerm,
            ...parameters,
        });

        yield put(staticItemsFetchSuccess({ assets, nextUrl }));
    } catch (error) {
        yield put(staticItemsFetchError());
        reportMessageToSentry({
            message: 'Failed to fetch static videos',
            extras: {
                error,
            },
        });
    }
}

export function* fetchMoreStaticVideos() {
    try {
        const { nextUrl: currentUrl } = yield select(getStaticItemsData);

        if (!currentUrl) {
            yield put(staticItemsFetchSuccess({ assets: [] }));
            return;
        }
        const { assets, nextUrl } = yield call(svpApiProxyClient.fetchMoreAssets, currentUrl);

        yield put(staticItemsFetchSuccess({ assets, nextUrl }));
    } catch (error) {
        yield put(staticItemsFetchError());
        reportMessageToSentry({
            message: 'Failed to fetch more static videos',
            extras: {
                error,
            },
        });
    }
}

export function* fetchPlaylistsFromSearch({ payload: { searchTerm, providers } }) {
    try {
        const { items } = yield select(getStaticItemsData);

        for (const provider of providers) {
            const playlistPerProvider = keyBy(items, 'provider');
            const fetchedPlaylists = playlistPerProvider[provider];
            const page = fetchedPlaylists?.page || 1;

            if (fetchedPlaylists?.hasNext === false) {
                yield put(
                    staticPlaylistsFetchSuccess({
                        playlists: [],
                        provider,
                    })
                );
            } else {
                const playlists = yield call(adminBffClient.fetchPlaylists, {
                    provider,
                    name: searchTerm,
                    limit: PAGINATION_LIMIT,
                    page,
                });
                const onlyRegularPlaylists = playlists.filter((playlist) => !isPlaylistOfPlaylists(playlist));
                const hasNext = onlyRegularPlaylists.length >= PAGINATION_LIMIT - 1;
                const newPage = hasNext ? page + 1 : page;

                yield put(
                    staticPlaylistsFetchSuccess({
                        playlists: onlyRegularPlaylists.map((playlist) => ({ ...playlist, hasNext, page: newPage })),
                        provider,
                    })
                );
                yield put(playlistsAssetsFetch({ provider, playlists: onlyRegularPlaylists }));

                yield call(fetchPlaylistsAssetsController, { playlists: onlyRegularPlaylists, provider });
            }
        }
    } catch (error) {
        yield put(staticItemsFetchError());
        reportMessageToSentry({
            message: 'Failed to fetch playlists',
            extras: {
                error,
            },
        });
    }
}

export default [
    takeEvery(STATIC_VIDEOS_SEARCH, fetchStaticVideos),
    throttle(2000, STATIC_VIDEOS_SEARCH_MORE, fetchMoreStaticVideos),
    takeEvery(PLAYLISTS_SEARCH, fetchPlaylistsFromSearch),
];
