import config from 'config';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFieldArray, Controller } from 'react-hook-form';
import type { UseFormSetValue } from 'react-hook-form';
import { omit } from 'lodash';
import ScreenContainer from 'components/ui/ScreenContainer';
import {
    LabeledContainer,
    Input,
    Select,
    Radio,
    Checkbox,
    Button,
    Spinner,
    Notification,
    Divider,
} from '@schibsted-svp/react-ui';
import { LABELS } from 'screens/NewsroomScreen/PlaylistsScreen/utils';
import TravoltaGif from 'gifs/so-really.gif';
import { MdAccessTime, MdVideoLibrary, MdSettings } from 'react-icons/md';
import { isPlaylistExisting, getPlaylistFetchingState } from 'store/playlists/selectors';
import OrderDropdown from 'components/core/OrderDropdown';
import AppliedFilters from 'components/AssetsList/AppliedFilters';
import { FiltersDialog } from 'components/core/FiltersDialog';
import { findMultipleOptions, getValuesFromOptionsArray } from 'components/ui/Form/Select';
import { useUserPermissions } from 'hooks/usersManagement/useUserPermissions';
import { playlistsFiltersClear } from 'store/playlists/filters';
import { playlistsGeneratorAssetsFetchSuccess } from 'store/playlists/items';
import { getFormattedTime } from 'lib/time';
import { useCategoryCustomFieldsPerType } from 'components/core/FiltersDialog/hooks';

import { useAppSelector } from 'store/hooks';
import generateSearchParameters from 'store/utils';
import { prepareQueryString } from 'services/api-client/assets';
import { createFilterString } from 'models/filter';
import { svpApiProxyClient } from 'services';
import { FiltersDialogData } from 'components/core/FiltersDialog/types';
import { removeEmptyValues } from 'store/asset-list/reducers';
import isBoolean from 'lodash/isBoolean';
import { PlaylistsFormItems } from './PlaylistsFormItems';
import { PlaylistMetadataDialog } from './PlaylistMetadataDialog';
import { usePlaylistForm } from './usePlaylistForm';
import { usePlaylistDuration } from './hooks';

import type { Playlist, FormValues, PlaylistItem } from './types';

import css from './PlaylistsScreenGenerator.module.scss';

const { sortTypes, playlists } = config;
const defaultSortTypeValue = sortTypes[2].value;

const playlistLabelOptions = playlists.labels.map(({ value, label }) => ({
    value: value as string,
    label: label as string,
}));

type Sort =
    | {
          readonly value: 'created' | 'published' | 'relevance';
          readonly label: string;
      }
    | { value: string };

type Filter = { filter: keyof FiltersDialogData; value: FiltersDialogData[keyof FiltersDialogData] };
interface PlaylistsScreenGeneratorProps {
    provider: string;
    playlist?: Playlist;
    order?: string;
    mode: 'new' | 'edit';
    pristine?: boolean;
}

export const getTypeDependentFilters = (filters: FiltersDialogData, type: string) => {
    switch (type) {
        case LABELS.PODCAST:
            return { ...filters, isPodcast: true, assetTypes: [{ label: 'audio', value: 'audio' }] };
        case LABELS.AUDIO:
            return { ...filters, assetTypes: [{ label: 'audio', value: 'audio' }] };

        default:
            return { ...filters, assetTypes: [{ label: 'video', value: 'video' }] };
    }
};

const getAppliedFilters = (
    provider: string,
    searchQuery: string,
    query: string,
    filters: FiltersDialogData,
    items: PlaylistItem[]
) => {
    const { newsrooms } = filters;

    const customFilters: FiltersDialogData = omit(filters, ['statuses', 'assetTypes', 'types', 'newsrooms']);
    const areFiltersEmpty = !query && Object.values(customFilters).filter(Boolean).length === 0;
    const onlyCurrentNewsroom = newsrooms?.length === 1 && newsrooms.includes(provider);

    if ((areFiltersEmpty && onlyCurrentNewsroom) || !searchQuery) {
        return {};
    }

    const excludedAssets = customFilters.excludedAssets?.filter(
        (excludedAssetId) =>
            !items.some((item) => item.isStatic && 'asset' in item && item.asset.id === excludedAssetId)
    );

    const appliedFilters = {
        ...omit(customFilters, 'customFields', 'excludedAssets'),
        ...customFilters.customFields,
        ...(excludedAssets?.length && { excludedAssets }),
    };

    if (onlyCurrentNewsroom) {
        return appliedFilters;
    }

    return {
        ...appliedFilters,
        newsrooms: newsrooms?.map((value) => ({
            value,
            label: config.newsrooms[value],
        })),
    };
};

function getCleanedFilters(currentFilters, { filter, value, newsroom }) {
    const newFilters = { ...currentFilters };

    if (newFilters?.customFields && Object.keys(newFilters.customFields).includes(filter)) {
        return {
            ...newFilters,
            customFields: omit(newFilters.customFields, filter),
        };
    }

    if (isBoolean(value)) {
        return {
            ...newFilters,
            [filter]: false,
        };
    }

    if (filter === 'categories' && newFilters?.customFields) {
        delete newFilters.customFields;
    }

    const newFilter = ['after', 'before', 'min', 'max', 'categories', 'createdBy'].includes(filter)
        ? null
        : newFilters[filter].filter((item) => (typeof item === 'object' ? item.value !== value : item !== value));

    if (!newFilter || newFilter.length === 0) {
        if (filter === 'newsrooms') {
            return {
                ...newFilters,
                newsrooms: [newsroom],
            };
        }
        return omit(newFilters, [filter]);
    }

    newFilters[filter] = newFilter;

    return newFilters;
}

// eslint-disable-next-line consistent-return
async function fetchFilteredAssets({
    filters,
    sortType,
    podcastOnly,
    setIsSearching,
    searchQuery,
    setValue,
    limit,
    query,
    provider,
}: {
    filters: FiltersDialogData;
    sortType?: Sort[];
    podcastOnly?: boolean;
    setIsSearching: (isSearching: boolean) => void;
    searchQuery: string;
    setValue: UseFormSetValue<FormValues>;
    limit: number;
    query: string;
    provider: string;
}) {
    try {
        const audioFilters = {
            assetTypes: [{ label: 'audio', value: 'audio' }],
        };
        const podcastFilters = {
            isPodcast: true,
            ...audioFilters,
        };
        setIsSearching(true);
        const searchParameters = generateSearchParameters(
            { ...filters, ...(podcastOnly ? podcastFilters : {}) },
            sortType[0] || {
                value: new URLSearchParams(searchQuery).get('sort') || 'created',
            }
        );
        const newsroomFilters = filters?.newsrooms;
        const areAssetsFromCurrentNewsroom = newsroomFilters?.length === 1 && newsroomFilters.includes(provider);
        const preparedQuery = prepareQueryString({
            query,
            ...(!areAssetsFromCurrentNewsroom && { searchQueryProviders: newsroomFilters }),
            before: searchParameters.before,
            after: searchParameters.after,
            filter: createFilterString(searchParameters.filters),
            order: searchParameters.order,
            limit,
        });
        setValue('searchQuery', new URLSearchParams(preparedQuery).toString());
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { assets } = await svpApiProxyClient.fetchFilteredAssets({
            provider,
            searchQueryProviders: areAssetsFromCurrentNewsroom ? provider : newsroomFilters,
            query,
            limit,
            ...searchParameters,
        });
        return assets;
    } catch (err) {
        Notification.notify.error('There was an error during filtering videos');
    }
}

export function PlaylistsScreenGenerator({
    provider,
    playlist,
    order,
    mode,
    pristine = true,
}: PlaylistsScreenGeneratorProps) {
    const { formApi, onSubmit } = usePlaylistForm({ playlist });
    const { register, control, getValues, setValue, formState, watch } = formApi;

    const fieldArrayApi = useFieldArray({
        control,
        name: 'items',
    });

    const [sort, setSort] = useState(order ? { value: order } : sortTypes[0]);
    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();
    const { newsroom, playlistId } = useParams();
    const { errors } = formState;

    const watchedValues = watch(['searchQuery', 'isPodcastPlaylist', 'type', 'limit', 'items', 'query']);

    const [searchQuery, isPodcastPlaylist, type, limit, items, query] = watchedValues;

    const staticItems = items.filter(({ isStatic }) => isStatic);

    const userPermissions = useUserPermissions();

    const queryFromSearch = new URLSearchParams(searchQuery).get('query');

    const isRegularPlaylist = type !== LABELS.POP;
    const duration = usePlaylistDuration({ items, isRegularPlaylist });

    const initialFilters = useAppSelector((state) => state.playlists.filters);
    const [filters, setFilters] = useState(initialFilters);
    const playlistExists = useSelector((state) => isPlaylistExisting(state, provider, playlistId));
    const [typeDependentFilters, setTypeDependentFilters] = useState(getTypeDependentFilters(filters, type));

    useEffect(() => {
        setFilters(initialFilters);
    }, [initialFilters]);

    useEffect(() => {
        setTypeDependentFilters(getTypeDependentFilters(filters, type));
    }, [filters, isPodcastPlaylist, type]);

    useEffect(() => {
        if (order) {
            setSort({ value: order });
        } else {
            setSort({ value: defaultSortTypeValue });
        }
    }, [order]);

    const isPlaylistFetching = useSelector((state) => getPlaylistFetchingState(state, { newsroom, playlistId }));

    const handleSubmit = onSubmit(mode, provider, isRegularPlaylist);

    const handleSearch = async ({
        sortType = [sort],
        podcastOnly = isPodcastPlaylist,
        currentSearchQuery,
        currentQuery,
        currentFilters,
        currentLimit,
        currentItems = items,
    }: {
        sortType?: Sort[];
        podcastOnly?: boolean;
        currentSearchQuery: string;
        currentQuery: string;
        currentFilters: FiltersDialogData;
        currentLimit: number;
        currentItems?: PlaylistItem[];
    }) => {
        const showHiddenDefault = !(currentFilters?.showHidden === undefined);

        const adjustedFilters = mode === 'new' ? { ...currentFilters, showHidden: showHiddenDefault } : currentFilters;

        const assets = await fetchFilteredAssets({
            filters: adjustedFilters,
            sortType,
            podcastOnly,
            setIsSearching,
            searchQuery: currentSearchQuery,
            setValue,
            limit: currentLimit,
            query: currentQuery,
            provider,
        });

        dispatch(
            playlistsGeneratorAssetsFetchSuccess({
                assets,
                provider,
                playlistId,
            })
        );
        const allItems = assets.map((asset) => ({
            asset: {
                id: asset.id,
                provider: asset.provider,
                category: asset.category.title,
            },
        }));
        const staticVideos = currentItems
            .map((item, index) => ({ ...item, position: index }))
            .filter(({ isStatic }) => isStatic);
        staticVideos.forEach((item) => {
            allItems.splice(item.position, 0, item);
        });
        fieldArrayApi.replace(allItems.map((item, index) => ({ ...item, position: index })));
        setIsSearching(false);
    };

    const handleSortTypeChange = (sortType: Sort) => {
        setSort(sortType);
        handleSearch({
            sortType: [sortType],
            currentSearchQuery: searchQuery,
            currentQuery: query,
            currentFilters: filters,
            currentLimit: limit,
        });
    };

    const handleQueryDeletion = async () => {
        setValue('query', '');
        const searchParams = new URLSearchParams(searchQuery);
        searchParams.delete('query');

        setValue('searchQuery', searchParams.toString());

        await handleSearch({
            currentSearchQuery: searchParams.toString(),
            currentQuery: '',
            currentFilters: filters,
            currentLimit: limit,
        });
    };

    const handleFiltersClear = () => {
        const values = getValues();

        const currentStaticItems = values?.items?.filter(({ isStatic }) => isStatic) || [];
        const excludedAssets = filters.excludedAssets?.filter((excludedAssetId) =>
            currentStaticItems?.some((item) => 'asset' in item && item.asset.id === excludedAssetId)
        );

        dispatch(playlistsFiltersClear(newsroom, { ...(excludedAssets?.length && { excludedAssets }) }));
        setTypeDependentFilters(getTypeDependentFilters(filters, type));

        setValue('searchQuery', '');
        setValue('query', '');
        setValue('items', currentStaticItems);
    };

    const handleFiltersDeletion = (filtersToRemove: Filter) => {
        let podcastOnly = isPodcastPlaylist;
        if (filtersToRemove.filter === 'isPodcast') {
            setValue('isPodcastPlaylist', false);

            podcastOnly = false;
        }

        const nextFilters = getCleanedFilters(filters, {
            filter: filtersToRemove.filter,
            value: filtersToRemove.value,
            newsroom,
        });

        setFilters(nextFilters);
        handleSearch({
            podcastOnly,
            currentSearchQuery: searchQuery,
            currentQuery: query,
            currentFilters: nextFilters,
            currentLimit: limit,
        });
    };

    const handleFilter = async (dialogFilters: FiltersDialogData) => {
        setValue('isPodcastPlaylist', dialogFilters.isPodcast);

        const adjustedFilters = { ...removeEmptyValues(dialogFilters), showHidden: dialogFilters?.showHidden };

        setFilters(adjustedFilters);
        await handleSearch({
            currentSearchQuery: searchQuery,
            currentQuery: query,
            currentFilters: adjustedFilters,
            currentLimit: limit,
        });
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && isRegularPlaylist && !errors.limit) {
            event.preventDefault();
            handleSearch({
                currentSearchQuery: searchQuery,
                currentQuery: query,
                currentFilters: filters,
                currentLimit: limit,
            });
        }
    };

    const onPodcastFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilters(getCleanedFilters(filters, { filter: 'isPodcast', value: true, newsroom }));

        handleSearch({
            currentSearchQuery: searchQuery,
            currentQuery: query,
            podcastOnly: event.target.checked,
            currentFilters: filters,
            currentLimit: limit,
        });
    };

    const pinAssetItem = async (index: number) => {
        const item = items[index];

        if (!item || !('asset' in item)) {
            return;
        }

        const newItems = [...items];
        newItems[index] = { ...item, isStatic: true };

        const newFilters = {
            ...filters,
            excludedAssets: [...(filters.excludedAssets || []), item.asset.id],
        };

        await handleSearch({
            currentSearchQuery: searchQuery,
            currentQuery: query,
            currentFilters: newFilters,
            currentLimit: limit,
            currentItems: newItems,
        });

        setFilters(newFilters);
    };

    const removeAssetItem = async (index: number) => {
        const item = items[index];

        if (!item || !('asset' in item)) {
            return;
        }

        const excludedAssets = filters.excludedAssets || [];
        const isExcludedAsset = excludedAssets.includes(item.asset.id);

        if (!isExcludedAsset && item.isStatic) {
            fieldArrayApi.remove(index);
            return;
        }

        const newItems = [...items];

        if (item.isStatic) {
            newItems[index] = { ...item, isStatic: false };
        }

        const newFilters = {
            ...filters,
            excludedAssets: isExcludedAsset
                ? excludedAssets.filter((id: number) => id !== item.asset.id)
                : [...excludedAssets, item.asset.id],
        };

        await handleSearch({
            currentSearchQuery: searchQuery,
            currentQuery: query,
            currentFilters: newFilters,
            currentLimit: limit,
            currentItems: newItems,
        });

        setFilters(newFilters);
    };

    const categoryCustomDateFields = useCategoryCustomFieldsPerType({
        provider: newsroom,
        type: 'date',
        filters,
    });
    const categoryCustomBooleanFields = useCategoryCustomFieldsPerType({
        provider: newsroom,
        type: 'checkbox',
        filters,
    });

    if (mode === 'edit' && !playlistExists) {
        if (isPlaylistFetching) {
            return (
                <ScreenContainer>
                    <Spinner containerClassName={css.spinner} />
                </ScreenContainer>
            );
        }

        return (
            <div className={css.notFound}>
                Requested playlist can&apos;t be found
                <img alt="404" src={TravoltaGif} className={css.travolta} />
            </div>
        );
    }

    return (
        <ScreenContainer>
            <form onSubmit={handleSubmit} className={css.form}>
                <div onKeyDown={handleOnKeyDown} className={css.meta}>
                    <LabeledContainer label="Playlist title">
                        <Input
                            {...register('name')}
                            size="small"
                            className={css.formInputs}
                            placeholder="name"
                            autoComplete="off"
                            error={errors?.name?.message}
                        />
                    </LabeledContainer>
                    <LabeledContainer label="Header">
                        <Input {...register('header')} size="small" className={css.formInputs} placeholder="header" />
                    </LabeledContainer>
                    <div className={css.metaRow}>
                        <LabeledContainer label="Labels">
                            <Controller
                                name="labels"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isMulti
                                        options={playlistLabelOptions}
                                        value={findMultipleOptions(playlistLabelOptions)(field.value)}
                                        onChange={(value) => field.onChange(getValuesFromOptionsArray(value))}
                                        width={340}
                                        size="small"
                                    />
                                )}
                            />
                        </LabeledContainer>
                        <LabeledContainer label="Playlist type">
                            <div className={css.type}>
                                <Radio {...register('type')} defaultValue={LABELS.VIDEO} label="Video" disabled />
                                <Radio {...register('type')} defaultValue={LABELS.AUDIO} label="Audio" disabled />
                                <Radio {...register('type')} defaultValue={LABELS.POP} label="Playlists" disabled />
                                {type === LABELS.AUDIO ? (
                                    <Checkbox
                                        {...register('isPodcastPlaylist')}
                                        defaultValue={LABELS.PODCAST}
                                        onChange={onPodcastFilterChange}
                                        label="Podcasts only"
                                    />
                                ) : null}
                            </div>
                        </LabeledContainer>
                        <LabeledContainer label="Playlist duration" className={css.durationContainer}>
                            <div className={css.duration}>
                                <MdAccessTime color={css.silver2} size={15} className={css.icon} />
                                {getFormattedTime(duration)}
                            </div>
                        </LabeledContainer>
                    </div>
                    <PlaylistMetadataDialog formApi={formApi} handleSubmit={handleSubmit} />
                    <Divider margin="5px 0" />
                    {isRegularPlaylist && (
                        <div className={css.criteria}>
                            <div className={css.search}>
                                <LabeledContainer label="Generate playlist based on">
                                    <Input
                                        {...register('query')}
                                        size="small"
                                        className={css.formInputs}
                                        placeholder="Search"
                                        autoComplete="off"
                                    />
                                </LabeledContainer>
                                <LabeledContainer>
                                    <Button
                                        iconOnly
                                        size="small"
                                        type="button"
                                        variant="standard"
                                        className={css.searchButton}
                                        onClick={() =>
                                            handleSearch({
                                                currentSearchQuery: searchQuery,
                                                currentQuery: query,
                                                currentFilters: filters,
                                                currentLimit: limit,
                                            })
                                        }
                                    >
                                        <MdSettings color={css.white} />
                                    </Button>
                                </LabeledContainer>
                            </div>
                            <LabeledContainer>
                                <OrderDropdown
                                    size="small"
                                    selectedSort={sort}
                                    onChange={handleSortTypeChange}
                                    className={css.order}
                                    menuGap={14}
                                    menuArrowPosition={125}
                                />
                            </LabeledContainer>
                            <LabeledContainer>
                                <FiltersDialog
                                    provider={provider}
                                    onFilter={handleFilter}
                                    stopFormPropagation
                                    initialValues={typeDependentFilters}
                                    newsroomsSelect
                                    showHiddenCheckbox
                                    hideAssetTypeField
                                    dialogTrigger={(onClick) => (
                                        <Button onClick={onClick} type="button" size="small" variant="standard">
                                            Filters
                                        </Button>
                                    )}
                                />
                            </LabeledContainer>
                            <LabeledContainer label="Search query limit">
                                <Input
                                    {...register('limit', { valueAsNumber: true })}
                                    type="number"
                                    min={1}
                                    max={100}
                                    icon={<MdVideoLibrary color={css.purpleHeart} />}
                                    iconPosition="right"
                                    size="small"
                                    className={css.formInputs}
                                    placeholder="limit"
                                    autoComplete="off"
                                    error={errors.limit?.message}
                                />
                            </LabeledContainer>
                        </div>
                    )}
                    {isRegularPlaylist && (
                        <AppliedFilters
                            onFilterDeletion={handleFiltersDeletion}
                            onFiltersClear={handleFiltersClear}
                            query={queryFromSearch}
                            filters={getAppliedFilters(provider, searchQuery, query, typeDependentFilters, items)}
                            onQueryDeletion={handleQueryDeletion}
                            categoryCustomDateFields={categoryCustomDateFields}
                            categoryCustomBooleanFields={categoryCustomBooleanFields}
                            clearButtonLabel="Remove dynamic elements"
                            forceShow={items.length !== staticItems.length}
                        />
                    )}
                </div>
                <PlaylistsFormItems
                    fieldArrayApi={fieldArrayApi}
                    isRegularPlaylist={isRegularPlaylist}
                    getValues={getValues}
                    provider={provider}
                    formState={formState}
                    isPristine={pristine}
                    isPlaylistFetching={isPlaylistFetching}
                    disabledSaving={!userPermissions?.isUser || Boolean(errors.limit)}
                    isSearching={isSearching}
                    pinAssetItem={pinAssetItem}
                    removeAssetItem={removeAssetItem}
                />
            </form>
        </ScreenContainer>
    );
}
