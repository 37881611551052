import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { GoIssueOpened } from 'react-icons/go';
import { MdCheck } from 'react-icons/md';
import { CopyToClipboard, Button } from '@schibsted-svp/react-ui';
import css from './SubmissionError.module.scss';

/**
 * Submission error component with an optional copy2clipboard button.
 * When clipboardText param is provided, it's value will be base64 encoded
 * and copied to os's clipboard after the button is clicked.
 */
function SubmissionError({ children, clipboardText, className }) {
    const [copied, setCopied] = useState(false);
    const handleCopy = (copiedText, result) => setCopied(result);

    return (
        <div className={`${css.wrapper} ${className}`}>
            <GoIssueOpened className={css.icon} />
            {children}
            {clipboardText && (
                <div className={css.clipboard}>
                    {copied ? (
                        <span className={css.confirm}>
                            Copied <MdCheck />
                        </span>
                    ) : (
                        <CopyToClipboard
                            text={window.btoa(unescape(encodeURIComponent(clipboardText)))}
                            onCopy={handleCopy}
                        >
                            <Button type="button" variant="danger" size="compact">
                                Copy error code
                            </Button>
                        </CopyToClipboard>
                    )}
                </div>
            )}
        </div>
    );
}

SubmissionError.propTypes = {
    /** Error message */
    children: PropTypes.node,
    /** Optional text which should be copied to a clipboard. If provided then a copy button will be rendered */
    clipboardText: PropTypes.string,
    /** Optional className to customize the button */
    className: PropTypes.string,
};

export default SubmissionError;
