import PropTypes from 'prop-types';
import { CUTTING_STATE } from 'store/video/cutting/reducers';
import VideoCard from 'components/core/VideoCard';
import VideoCardProgress from 'components/core/VideoCard/VideoCardProgress';
import { MdWarning } from 'react-icons/md';
import css from './ClipCard.module.scss';

function ClipCard({ provider, id, transcoding, className = '' }) {
    const { state, progress = 0 } = transcoding || {};

    const progressElement = <VideoCardProgress progress={progress} variant={state} />;

    const getCustomType = () => {
        if (state === CUTTING_STATE.TRANSCODE) {
            const progressValue = Number(progress).toFixed(0);
            return progress > 0 ? `CLIP CREATION ${progressValue}%` : 'CLIP CREATION SCHEDULED';
        }

        if (state === CUTTING_STATE.ERROR) {
            return (
                <span className={css.errorType}>
                    <MdWarning className={css.warningIcon} /> CLIP CREATION FAILED
                </span>
            );
        }

        return null;
    };

    return (
        <VideoCard
            readOnly
            className={className}
            progressElement={progressElement}
            variant="simple"
            provider={provider}
            id={id}
            customType={getCustomType()}
        />
    );
}

ClipCard.propTypes = {
    provider: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    transcoding: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default ClipCard;
